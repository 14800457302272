import { useEffect, useContext, useState, React, useRef } from "react";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Sort,
  Inject,
  Page,
  CommandColumn,
  DetailRow,
  Resize,
  Filter,
  Search,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { getValue } from "@syncfusion/ej2-base";
import { Tooltip } from "@syncfusion/ej2-popups";
import agent from "../../api/agent";
import TokenProvider, { logOutUser } from "../TokenProvider/TokenProvider";
import TripStop from "./TripStop";
import "./TripManagement.css";
import Loading from "../Loading/Loading.js";
import { useTranslation } from "react-i18next";
import { Animation } from '@syncfusion/ej2-base';
import { IoMdRefresh } from 'react-icons/io'
import { FiDownload } from 'react-icons/fi';
import { showToast } from '../Theme/Utils';
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import ScheduledStops from "./ScheduledStops";
import { Tab, Tabs } from 'react-bootstrap';
import ThemeContext from '../Theme/ThemeContext';

const TripManagement = () => {
  const { token } = TokenProvider();
  const [grid, setGrid] = useState([]);
  const dataGrid = useRef();
  const [loading, setLoading] = useState(true);
  const [lastRefreshedTrips, setLastRefreshedTrips] = useState();
  const [refreshTrip, setRefreshTrip] = useState();
  const { t, i18n } = useTranslation();
  const GridCompRef = useRef(null);
  const AppCompRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('assignedTrips');
  const { theme, localeState } = useContext(ThemeContext);

  const handleTabSelect = (key) => {
    setSelectedTab(key);
    if(key === "assignedTrips"){
      getTripsList();
    }
  }

  useEffect(() => {
    getTripsList();
  }, [token.token, refreshTrip]);

  const getTripsList = () => {
    //dataGrid.current.showSpinner();
    agent
    .fetchOpenTrips(token.token)
    .then((result) => {
      result.data.forEach((element) => {
        if (element?.firstStopScheBegin) {
          element.firstStopScheBegin = new Date(element.firstStopScheBegin);
        }
        if (element?.lastStopSchEnd) {
          element.lastStopSchEnd = new Date(element.lastStopSchEnd);
        }
        element.statusDescription_fr = t(element.statusDescription,{ lng: 'fr' })
        element.reference_str = element.reference ? element.reference : '';
      });
      setGrid(result.data);
      setLastRefreshedTrips(new Date().toLocaleString("en-US"));
    })
    .catch((error) => {
      console.log("Error in calling API: ", error);
      if (error?.response?.status === 401) {
        showToast(t('Your session has expired. Please login again.'), 'error');
        logOutUser();
      }
    })
    .finally(() => {
      //dataGrid.current.hideSpinner();
      setLoading(false);
    });
  }

  useEffect(() => {
    if (token?.locale && dataGrid?.current) {
      dataGrid.current.refresh()
    }
  }, [token])

  useEffect(() => {
      if (selectedTab === "assignedTrips") {
        let animation = new Animation();
        animation.animate(GridCompRef.current, { name: 'FadeIn', duration: 800 });
      } else {
        let animation = new Animation();
        animation.animate(AppCompRef.current, { name: 'FadeIn', duration: 800 });
      }
  }, [selectedTab]);

  const rowStopDetail = (props) => {
    return (
      <TripStop
        token={token.token}
        user={token.username}
        tripID={props.tripNumber}
        tenant={props.tenant}
        data={grid}
        setData={setGrid}
        dataGrid={dataGrid}
      />
    );
  };

  const commandClickHandler = (e) => {
    //console.log(e);
    if (e.commandColumn.type === "pdf") {
      agent
        .getTenderConfirmationPDF(token.token, e.rowData.tripNumber, e.rowData.carrierID, e.rowData.tenant)
        .then((response) => {
          const linkSource = `data:application/pdf;base64,${response.data.Content}`;
          const downloadLink = document.createElement("a");
          const fileName = `TipManagement-${e.rowData.tripNumber}.pdf`;
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((error) => {
          showToast(t('Unable to get the Trip Confirmation.'), 'error');
          console.log("Error in calling API: ", error);
          if (error?.response?.status === 401) {
            showToast(t('Your session has expired. Please login again.'), 'error');
            logOutUser();
          }
        });
    }
  };

  const commandPDF = [
    {
      type: "pdf",
      buttonOption: { content: "Dispatch Copy", cssClass: "e-outline" },
    },
  ];

  const pickupFormatter = (field, data, column) => {
    let city = !getValue("firstStopCity", data) ? "" : getValue("firstStopCity", data);
    let state = !getValue("firstStopState", data) ? "" : getValue("firstStopState", data);

    return (city + "," + state).toUpperCase();

  };

  const lastPickupFormatter = (field, data, column) => {
    let city = !getValue("lastStopCity", data) ? "" : getValue("lastStopCity", data);
    let state = !getValue("lastStopState", data) ? "" : getValue("lastStopState", data);

    return (city + ", " + state).toUpperCase();
  };

  const pickupDateFormatter = (field, data, column) => {
    if (getValue("statusDescription", data) === "Completed" || getValue("statusDescription", data) === "Delivered") {
      return getValue("firstPickupStopActualArrivalDateTime_local", data);
    }
    if (getValue("statusDescription", data) === "Enroute") {
      return getValue("firstPickupStopActualArrivalDateTime_local", data);
    }
    if (getValue("statusDescription", data) === "Assigned") {
      return getValue("firstStopSchEnd_Local", data);
    }
  };

  const deliveryDateFormatter = (field, data, column) => {
    if (getValue("statusDescription", data) === "Completed" || getValue("statusDescription", data) === "Delivered") {
      return getValue("delivery_Local", data);
    }
    if (getValue("statusDescription", data) === "Enroute") {
      return getValue("lastStopSchBegin_Local", data);
    }
    if (getValue("statusDescription", data) === "Assigned") {
      return getValue("lastStopSchBegin_Local", data);
    }
  };

  const headerToolTip = (args) => {
    if (args?.node?.innerText === "Status") {
      const tooltip = new Tooltip({
        content: "Assigned = Awaiting Pickup <br/> Enroute = Picked up <br/> Delivered = Delivery complete",
        display: "inline-block",
        margin: "60px",
      });
      tooltip.appendTo(args.node);
    }
  };

  const doubleClick = (args) => {
    var targetRowInfo = dataGrid.current.getRowInfo(args.target);

    if (targetRowInfo?.row?.querySelector(".e-detailrowexpand")) {
      dataGrid.current?.detailRowModule?.collapse(targetRowInfo?.rowIndex);
    } else {
      dataGrid.current?.detailRowModule?.expand(targetRowInfo?.rowIndex);
    }
  };

  function gridLoaded() {
    //	let header = options.htmlElement.querySelector("h5")
    //	if (!header) header = options.htmlElement
    //	const questionTitle = React.createElement("div", dangerouslySetInnerHTML: {__html: `<h5>${header.innerHTML}</h5>` });
    //var reactfindDomNode = React.; //e - detailindentcell
    //	reactfindDomNode.append("<h2>can't append to reactfindDomNode</h2>");
    //console.log(dataGrid.current);
    // dataGrid.current.autoFitColumns([
    // 	"tripNumber",
    // 	"reference",
    // 	"statusDescription",
    // 	"numberOfStops",
    // 	"firstStopScheBegin",
    // 	"tripMode",
    // 	"equipment",
    // 	"totalWeight",
    // 	"miles",
    // ]);

    dataGrid.current.refreshHeader();
    dataGrid.current.autoFitColumns();
  }

  return (
    <div className="container-flex" style={{ padding: "1.5rem", paddingBottom: "4rem" }} >
      <div className="custom-label mr-2 ml-2">
        <h1 className="mt-4 my-custom-text main-header-text">{t('Trip Management')}</h1>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "baseline", marginBottom: '1.5rem' }}>
          <div>
            <Tabs defaultActiveKey="assignedTrips" id="tripTabs" variant="tabs" 
            // activeKey={selectedTab} 
            onSelect={handleTabSelect} >
              <Tab eventKey="assignedTrips" title={t("Assigned Trips")}>
              </Tab>
              <Tab eventKey="appointments" title={t("Appointments")}>
              </Tab>
            </Tabs>
          </div>
          <div className="my-custom-text" style={{ display: "flex", flexDirection: "row", alignItems: "baseline", }}>
            <label style={{ marginRight: "1rem" }} className="text mt-4">
              {t('Last Refreshed')}: {lastRefreshedTrips === undefined ? t("Refreshing...") : lastRefreshedTrips}
            </label>
            <div className="btn btn-outline-secondary active flex-center" type="button" onClick={(e) => {
              setLastRefreshedTrips();
              setRefreshTrip(!refreshTrip);
            }}>
              <IoMdRefresh style={{ color: 'white', marginRight: '5px' }} />
              {t('Refresh')}
            </div>
          </div>
        </div>
      </div>

   {selectedTab === "assignedTrips" &&
      <div ref={GridCompRef} >
        {loading && grid.length === 0 && (
          <div
            className="m-5 p-5"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignTtems: "center",
            }}
          >
            <Loading></Loading>
          </div>
        )}
        {grid && !loading && (
          <ErrorBoundary compName="Trip mgmt : GridComponent">
            <GridComponent
              dataSource={grid}
              className="mr-3 ml-3"
              id="trip-search-table"
              locale={localeState === "fr" ? 'fr-FR' : 'en-US'}
              allowResizing={true}
              textWrapSettings={{ wrapMode: "Both" }}
              allowSorting={true}
              allowMultiSorting={true}
              allowTextWrap={true}
              allowPaging={true}
              pageSettings={{ pageSize: 16 }}
              allowFiltering={(grid && grid.length > 0)}
              //onFailure={(args) => console.log(args)}
              detailTemplate={rowStopDetail}
              commandClick={commandClickHandler}
              //editSettings={{ allowEditing: true, allowDeleting: true }}
              filterSettings={{
                type: "Menu",
              }}
              ref={dataGrid}
              headerCellInfo={headerToolTip}
              recordDoubleClick={doubleClick}
              //detailDataBound={gridLoaded}
              toolbar={["Search"]}
              dataBound={gridLoaded}
              resizeSettings={{ mode: "Auto" }}
              sortSettings={{
                columns: [{ field: 'firstStopScheBegin', direction: 'Descending' }]
              }}
            >
              <ColumnsDirective>
                <ColumnDirective
                  // headerText="Trip#"
                  headerText={t("Trip#")}
                  // headerTemplate={()=>(
                  //   <div>{t("Trip#")}</div>
                  // )}
                  field="tripNumber"
                  textAlign="Left"
                  //width={100}
                  isPrimaryKey={true}
                  type="string"
                  template={(data) => (
                    <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                      <div>{data.tripNumber}</div>
                      <FiDownload onClick={() => commandClickHandler({ commandColumn: { type: "pdf" }, rowData: data })} className="action-btn-table" title={t("Download")} />
                    </div>)}
                />
                <ColumnDirective
                  // headerText="Ref#"
                  headerText={t("Ref#")}
                  // headerTemplate={()=>(
                  //   <div>{t("Ref#")}</div>
                  // )}
                  // field="reference"
                  field="reference_str"
                  textAlign="Left"
                  type="string"
                  // valueAccessor={(field, data) => data[field] !== null ? data[field] : ''}
                //width={100}
                />
                <ColumnDirective
                  // headerText="Status"
                  headerText={t("Status")}
                  // headerTemplate={()=>(
                  //   <div>{t("Status")}</div>
                  // )}
                  // field="statusDescription"
                  field={localeState === "fr" ? "statusDescription_fr" : "statusDescription"}
                  type="string"
                //	maxWidth={120}
                />
                <ColumnDirective
                  // headerText="P/U Date"
                  headerText={t("P/U Date")}
                  // headerTemplate={()=>(
                  //   <div>{t("P/U Date")}</div>
                  // )}
                  field="firstStopScheBegin"
                  type="date"
                  format="MM/dd/yyyy"
                  textAlign="Left"
                  //maxWidth={120}
                  valueAccessor={pickupDateFormatter}
                />
                <ColumnDirective
                  // headerText="First P/U"
                  headerText={t("Sch. First<br>P/U")}
                  disableHtmlEncode={false}
                  // headerTemplate={()=>(
                  //   <div>{t("First P/U")}</div>
                  // )}
                  field="firstPickCityState"
                  headerTextAlign="Center"
                  textAlign="Left"
                  //	maxWidth={120}
                  valueAccessor={pickupFormatter}
                  type="string"
                />
                <ColumnDirective
                  // headerText="Del Date"
                  headerText={t("Sch. Del<br>Date")}
                  // headerTemplate={()=>(
                  //   <div>{t("Del Date")}</div>
                  // )}
                  headerTextAlign="Center"
                  field="lastStopSchEnd"
                  type="date"
                  format="MM/dd/yyyy"
                  textAlign="Left"
                  //maxWidth={120}
                  disableHtmlEncode={false}
                  valueAccessor={deliveryDateFormatter}
                />
                <ColumnDirective
                  // headerText="Last Drop"
                  headerText={t("Last Drop")}
                  // headerTemplate={()=>(
                  //   <div>{t("Last Drop")}</div>
                  // )}
                  field="lastDropCityState"
                  textAlign="Left"
                  //	maxWidth={120}
                  valueAccessor={lastPickupFormatter}
                  type="string"
                />
                <ColumnDirective
                  // headerText="Stops"
                  headerText={t("Stops")}
                  // headerTemplate={()=>(
                  //   <div>{t("Stops")}</div>
                  // )}
                  field="numberOfStops"
                  type="number"
                  textAlign="Left"
                />
                <ColumnDirective
                  // headerText="Mode"
                  headerText={t("Mode")}
                  // headerTemplate={()=>(
                  //   <div>{t("Mode")}</div>
                  // )}
                  field="tripMode"
                  type="string"
                  textAlign="Left"
                />
                <ColumnDirective
                  // headerText="Equipment"
                  headerText={t("Equipment")}
                  // headerTemplate={()=>(
                  //   <div>{t("Equipment")}</div>
                  // )}
                  field="equipment"
                  textAlign="Left"
                  type="string"
                />
                <ColumnDirective
                  // headerText="Weight (lb)"
                  headerText={t("Weight<br />(lb)")}
                  // headerTemplate={()=>(
                  //   <div>{t("Weight")}<br />{t("(lb)")}</div>
                  // )}
                  field="totalWeight"
                  format="N"
                  textAlign="Left"
                  disableHtmlEncode={false}
                  type="number"
                />
                <ColumnDirective
                  // headerText="Miles"
                  headerText={t("Miles")}
                  // headerTemplate={()=>(
                  //   <div>{t("Miles")}</div>
                  // )}
                  field="miles"
                  format="N"
                  textAlign="Left"
                  type="number"
                />
                {/* <ColumnDirective
              headerText={"Dispatch <br/> Download")}
              textAlign="Left"
              commands={commandPDF}
              disableHtmlEncode={false}
            /> */}
                <ColumnDirective headerText="CarrierID" textAlign="Left" field="carrierID" width={0} visible={false} />
              </ColumnsDirective>
              <Inject services={[Sort, Page, CommandColumn, DetailRow, Resize, Filter, Search, Toolbar]} />
            </GridComponent>
          </ErrorBoundary>
        )}
      </div>}
      {selectedTab === "appointments" &&
      <div ref={AppCompRef} >
      { lastRefreshedTrips !== undefined && <ScheduledStops id = {lastRefreshedTrips} /> }
      </div>}
    </div>
  );
};

export default TripManagement;

import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
//import { FormValidator } from "@syncfusion/ej2-react-inputs";
import agent from "../../api/agent";
import { logOutUser } from '../TokenProvider/TokenProvider';
import {showToast} from '../Theme/Utils';

const AddDocument = (props) => {
	//let formObjectEvent;
	const uploader = useRef(null);
	////
	const [pdfFile, setPdf] = useState("");
	const { t, i18n } = useTranslation();


	const setSelected = (props) => {
		setPdf(props.filesData[0].rawFile);
	};

	const onSubmitEvent = async (e) => {
		e.preventDefault();
		//sanity check

		// Note: When the post document happens, Eventype will be 0, StopTypeEnum will be empty string.TMSWriteSucces, TMSWriteDate will be Null, 
		agent
			.PostDocument(props.token, props.tripID, props.sequenceID, pdfFile, props.user, props.tenant)
			.then((response) => {
				//console.log(response);
				if (response.status === 200) {
					//setEventDateTime();
					setPdf();
					//alert("Added event status");
					//setDelayReason();
					props.successful.current = true;
					props.setDialogueVisible(false);
					props.closeHandler();

				}
			})
			.catch((error) => {
				console.log(error);
				if (error.response === undefined) {
					showToast(t('Unable to update event status. Please check your internet connection'), 'error');
					return;
				}
				if (error.response?.status === 401) {
					showToast(t('Unable to add event status, please login again'), 'error');
          			logOutUser();
					return;
				}
				if (error?.response?.data.length > 0) {
					showToast(t('Unable to add event status. Error:') + " " + error.response.data, 'error');
				} else {
					showToast(t('Unable to add event status.'), 'error');
				}
			});
	};

	useEffect(()=>{
		const uploadText = document.querySelector("#formEvent span.e-file-drop")
		if(uploadText){ uploadText.textContent = t("Or drop files here");}
	},[])

	return (
		<div className='container-flex'>
			<div className='control_wrapper' id='control_wrapper'>
				<div className='control_wrapper'>
					<form id='formEvent' className="pd-1">
						<div className='form-group'>
						<div className='my-custom-text form-field-label'> {t("Proof Of Delivery PDF")}</div>
							<div className='e-float-input'>
								<UploaderComponent
									type='file'
									name='uploader'
									ref={uploader}
									id='fileUpload'
									allowedExtensions='.pdf' //FIX
									multiple={false}
									minFileSize={20}
									removing={(a) => setPdf()}
									selected={(e) => {
										setSelected(e);
									}}
									buttons={{ browse: t("Browse"), }}								
								></UploaderComponent>
							</div>
							<div id='uploadError'></div>
						</div>
					</form>
					<div className='submitBtn my-custom-modal-footer'>
						<button className="my-custom-btn" onClick={onSubmitEvent} disabled={!pdfFile}>
							{t("Submit")}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddDocument;

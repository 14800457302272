import { ColumnDirective, ColumnsDirective, GridComponent, Inject } from '@syncfusion/ej2-react-grids';
import { useEffect, useState, useContext, useRef } from 'react';
import { Sort, Page, CommandColumn, DetailRow, Resize, Filter, Search } from '@syncfusion/ej2-react-grids';
import ThemeContext from '../Theme/ThemeContext';
import { DataManager, RemoteSaveAdaptor } from '@syncfusion/ej2-data';
import { Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import ScheduledStopDialog from './ScheduledStopDialog';
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import agent from '../../api/agent';
import { useTranslation } from 'react-i18next';
import { createElement } from '@syncfusion/ej2-base';
import { DataUtil } from "@syncfusion/ej2-data";
import moment from 'moment';
import Loading from "../Loading/Loading.js";

const ScheduledStops = () => {
  const { token } = TokenProvider();
  const { theme, localeState } = useContext(ThemeContext);

  const username = process.env.REACT_APP_API_USERNAME;
  const password = process.env.REACT_APP_API_PASSWORD;

  const authHeader = 'Basic ' + btoa(username + ':' + password);
  const { t, i18n } = useTranslation();

  const grid = useRef();
  const [data, setData] = useState([]);
  const [delayReason, setDelayReason] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    agent.GetScheduledAppointments(token.token).then((temp) => {
      if (temp?.data) {
        temp.data = temp?.data?.map((obj) => {
          const newObj = { ...obj };
          newObj.pk = `${obj.trip}-${obj.stopSeq}`;
          newObj.appointmentStatus_fr = t(obj.appointmentStatus, { lng: 'fr' })
          newObj.appointmentDate = (moment(obj.appointmentDate).isValid() ? new Date(obj.appointmentDate) : null)
          // newObj.appBook = false
          return newObj;
        });
        setData(temp.data);
      } else {
        setData([]);
      }
    }).finally(() => {
      setLoading(false);
    });
    APICallDelayReason()
  }, []);

  const dataSource = new DataManager({
    adaptor: new RemoteSaveAdaptor(),
    insertUrl: '/Home/Insert',
    json: data,
    removeUrl: '/Home/Delete',
    updateUrl: process.env.REACT_APP_API + `/UpdateScheduledApp?token=${token.token}`,
    headers: [
      {
        Authorization: authHeader,
      },
    ],
  });

  const actionComplete = (args) => {
    //console.log(args, 'args');

    if (args.requestType === 'beginEdit') {
      const dialog = args.dialog;
      dialog.headerContent.lastChild.innerText = `${t("Trip")}: ${args.rowData.trip}, ${t("Stop")}: ${args.rowData.stopSeq}`;
      const dialogHeader = args.dialog.element.querySelector('.e-dlg-header-content');
      args.dialog.element.classList.add('my-custom-edit-dialogue');
      if (dialogHeader) {
        if (theme === 'dark') {
          args.dialog.element.classList.add('isDarkTheme');
        } else {
          args.dialog.element.classList.remove('isDarkTheme');
        }
      }
    }

    if (args.requestType === 'beginEdit') {
      /** Add Validation Rules */
      const appointmentDateElement = document.getElementById('appointmentDate');
      const appReqCheckbox = args.form.ej2_instances[0].element.querySelector('#appReq');
      const isAppReqChecked = appReqCheckbox?.checked ? true : false;

      if (appointmentDateElement) {
        args.form.ej2_instances[0].addRules('appointmentDate', { required: true });
        if (!isAppReqChecked) {
          args.form.ej2_instances[0].addRules('appointmentDate', { required: true });
        } else {
          args.form.ej2_instances[0].removeRules('appointmentDate');
        }
      }

      setTimeout(() => {
          // let isChecked = () => args.form.querySelector('#appReq').checked;
          //   args.form.ej2_instances[0].addRules('appointmentDate', {
          //     required: [() => isChecked()],
          //   });
          //   if(args?.rowData?.appBook){
          //     args.form.ej2_instances[0].addRules('reasonCode', {
          //       required: [() => isChecked()],
          //     });
          //   }
        args.form.ej2_instances[0].addRules('confirmationNumber', {
          maxLength: [50, 'Please limit your input to a maximum of 50 characters.'],
        });
        args.form.ej2_instances[0].addRules('confirmationNote', {
          maxLength: 300,
        });
      });

      // if (args?.rowData?.appBook) {
      //   args.form.ej2_instances[0].addRules('reasonCode', { required: true });
      // } else {
      //  // args.form.ej2_instances[0].addRules('reasonCode', { required: true });
      //   args.form.ej2_instances[0].removeRules('reasonCode');
      // }

      // if(!args.rowData?.appReq) {
      //   args.form.ej2_instances[0].removeRules('reasonCode');
      // }
    }
  };

  function actionBegin(args) {
    if (args.requestType === 'save') {
      let loadingIcon = createElement('i', { className: 'e-icons e-refresh' });
      args.dialog.btnObj[0].element.prepend(loadingIcon);
    }
  }

  const APICallDelayReason = () =>{
    agent
    .fetchDelayReason(token.token)
    .then((response) => {
      if (response.data.length > 0) {
        response.data = response.data.map(ele => {
          return { id: ele.id, description: ele.description }
        })
      }
      setDelayReason(DataUtil.parse.parseJson(response.data));
    })
    .catch((error) => {
      console.log("Error in calling API: ", error);
      if (error?.response?.status === 401) {
        //showToast(t('Unable to add event status, please login again'), 'error');
        //logOutUser();
      }
    });
  }

  const DialogTemplate = (props) => {
    const a = [props, grid.current, delayReason];
    return <ScheduledStopDialog {...a} />;
  };

  const commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } }];

  return (
    <div>
      {loading && data.length === 0 && (
          <div
            className="m-5 p-5"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignTtems: "center",
            }}
          >
            <Loading></Loading>
          </div>
        )}
      {delayReason.length > 0 && !loading &&
      <GridComponent
        ref={grid}
        dataSource={dataSource}
        allowSorting={true}
        allowPaging={true}
        allowFiltering={(data && data.length>0)}
        filterSettings={{
          type: 'Menu',
        }}
        // id="grid"
        toolbar={["Search"]}
        id="trip-search-table"
        className='scheduled-stop-table'
        editSettings={{
          allowEditing: true,
          mode: 'Dialog',
          template: DialogTemplate,
        }}
        locale={localeState === "fr" ? 'fr-FR' : 'en-US'}
        actionComplete={actionComplete.bind(this)}
        actionBegin={actionBegin}
      >
        <ColumnsDirective>
          <ColumnDirective isPrimaryKey={true} headerText="" field="pk" visible={true} width={50} commands={commands} 
          allowFiltering={false} allowSorting={false} clipMode="Clip"
          />
          <ColumnDirective
            headerText={t('Trip<br>#')}
            disableHtmlEncode={false}
            headerTextAlign="Center"
            field="trip"
            width="130"
            textAlign="Right"
            type="string"
            template={({ trip }) => <div style={{ whiteSpace: 'pre-line' }}>{trip}</div>}
          />
          <ColumnDirective
            headerText={t('Stop<br>Sequence')}
            headerTextAlign="Center"
            disableHtmlEncode={false}
            field="stopSeq"
            width="130"
            textAlign="Center"
            type="number"
            template={({ stopSeq }) => <div style={{ whiteSpace: 'pre-line' }}>{stopSeq}</div>}
          />
          <ColumnDirective
            headerText={t('Order<br>Ref')}
            headerTextAlign="Center"
            disableHtmlEncode={false}
            field="orderRef"
            width="170"
            textAlign="Center"
            type="number"
            template={({ orderRef }) => <div style={{ whiteSpace: 'pre-line' }}>{orderRef}</div>}
          />
          <ColumnDirective
            headerText={t('Stop<br>Name')}
            disableHtmlEncode={false}
            headerTextAlign="Center"
            field="stopName"
            width="100"
            textAlign="Center"
            type="string"
            template={({ stopName }) => <div style={{ whiteSpace: 'pre-line' }}>{stopName}</div>}
          />
          <ColumnDirective
            headerText={t('Address')}
            headerTextAlign="Center"
            field="address"
            width="100"
            textAlign="Center"
            type="string"
            template={({ address }) => <div style={{ whiteSpace: 'pre-line' }}>{address}</div>}
          />
          <ColumnDirective headerText={t('City')} type="string" headerTextAlign="Center" textAlign="Center" field="city" width="100" />
          <ColumnDirective headerText={t('Province')} type="string" headerTextAlign="Center" textAlign="Center" field="province" width="100" />
          <ColumnDirective 
          headerText={t('Postal<br>Code')}  disableHtmlEncode={false} type="string"
          headerTextAlign="Center" field="postalCode" textAlign="Center" width="100" />
          <ColumnDirective
            headerText={t('Appointment<br>Book')}
            disableHtmlEncode={false}
            headerTextAlign="Center"
            field="appointmentDate"
            type="dateTime"
            format="MM/dd/yyyy HH:mm"
            // format="dd/MM/yyyy HH:mm"
            width="200"
            textAlign="Center"
            validationRules={{ required: true }}
          />
          <ColumnDirective
            headerText={t('Confirmation<br>Number')}
            disableHtmlEncode={false}
            headerTextAlign="Center"
            field="confirmationNumber"
            width="160"
            textAlign="Center"
            type="string"
            template={({ confirmationNumber }) => <div style={{ whiteSpace: 'pre-line' }}>{confirmationNumber}</div>}
          />
          <ColumnDirective
            headerText={t('Confirmation<br>Note')}
            disableHtmlEncode={false}
            headerTextAlign="Center"
            field="confirmationNote"
            width="200"
            textAlign="Center"
            type="string"
            template={({ confirmationNote }) => <div style={{ whiteSpace: 'pre-line' }}>{confirmationNote}</div>}
          />
          <ColumnDirective
            headerText={t('Appointment<br>Status')}
            disableHtmlEncode={false}
            headerTextAlign="Center"
            field={localeState === "fr" ? "appointmentStatus_fr" : "appointmentStatus"}
            width="160"
            textAlign="Center"
            type="string"
            template={({ appointmentStatus_fr, appointmentStatus }) => <div style={{ whiteSpace: 'pre-line' }}>{localeState === "fr" ? appointmentStatus_fr : appointmentStatus}</div>}
          />
          <ColumnDirective
            // headerText={t("Appointment<br>Status")}
            visible={false}
            disableHtmlEncode={false}
            //headerTextAlign="Center"
            field="appReq"
            width="50"
            type="boolean"
          //textAlign="Center"
          //template={({ appointmentStatus }) => (<div style={{ whiteSpace: "pre-line" }}>{appointmentStatus}</div>)}
          />
          <ColumnDirective
            // headerText={t("Appointment<br>Status")}
            visible={true}
            //disableHtmlEncode={false}
            //headerTextAlign="Center"
            width={1}
            field="reasonCode"
            validationRules={{ required: false }}
            type="string"
          //width="100"
          //textAlign="Center"
          //template={({ appointmentStatus }) => (<div style={{ whiteSpace: "pre-line" }}>{appointmentStatus}</div>)}
          />
          <ColumnDirective
            // headerText={t("Appointment<br>Status")}
            visible={false}
            //disableHtmlEncode={false}
            //headerTextAlign="Center"
            width={100}
            field="appBook"
            type="boolean"
          //validationRules={validationRules}
          //width="100"
          //textAlign="Center"
          />
        </ColumnsDirective>
        <Inject services={[Edit, Sort, Page, CommandColumn, DetailRow, Resize, Filter, Search, Toolbar]} />
      </GridComponent>
      }
    </div>
  );
};

export default ScheduledStops;
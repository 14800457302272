const Loading = () => {
	return (
		<div>
			<div className='spinner-border' role='status' style={{ color: "rgb(255, 212, 112)" }}>
				<span className='sr-only' style={{ color: "rgb(255, 212, 112)" }}></span>
			</div>
		</div>
	);
};

export default Loading;

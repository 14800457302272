import { React, useEffect, useRef, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DateTimePickerComponent, MaskedDateTime, Inject } from "@syncfusion/ej2-react-calendars";
import { useTranslation } from "react-i18next";
//import "./TripManagement.css";
import { enableRipple } from "@syncfusion/ej2-base";
import agent from "../../api/agent";
import { logOutUser } from '../TokenProvider/TokenProvider';
import {showToast} from '../Theme/Utils';

import TokenProvider from "../TokenProvider/TokenProvider";
import { json } from "react-router-dom";

const InOut = (props) => {
	const userDateRefIn = useRef(null);
	const userDateRefOut = useRef(null);
	const { token } = TokenProvider();
	const { t, i18n } = useTranslation();
	const [inTime, setInTime] = useState(props.inTime);
	const [outTime, setOutTime] = useState(props.outTime);

	enableRipple(true);

	const onSubmitEvent = async (e) => {
		//sanity check
		if (props.inTime === null || props.outTime === null) {
			return;
		}

		agent
      .PostEventInOut(
        props.token,
        props.tripID,
		new Date(inTime).toLocaleString("en-US"),
		new Date(outTime).toLocaleString("en-US"),
        props.sequenceID,
        token.username,
        props.eventType,
        props.stopType === 'ETA' ? 1 : props.stopType === 'ARRIVED' ? 2 : props.stopType === 'DEPARTED' ? 3 : 0,
        props.tenant
      )
      .then((response) => {
        if (response.status === 200) {
          setOutTime();
          setInTime();
          props.successful.current = true;
          props.setShowInOut(false);
          props.closeHandler();
        }
      })
      .catch((error) => {
        //console.log(error);
        if (error.response === undefined) {
          showToast(t('Unable to update event status. Please check your internet connection'), 'error');
          return;
        }
        if (error.response?.status === 401) {
          showToast(t('Unable to add event status, please login again'), 'error');
          logOutUser();
          return;
        }
        if (error?.response?.data.length > 0) {
          showToast(t('Unable to add event status. Error:') + ' ' + error.response.data, 'error');
        } else {
          showToast(t('Unable to add event status.'), 'error');
        }
      });
	};

	useEffect(() => {
		if (props?.inTime) {
			if (inTime > outTime && outTime) {
				//alert("Out time must be before In time");
				setOutTime();
				//alert();
			}
		}
	}, [props.outTime]);

	return (
		<div className='container-flex'>
			<div className='control_wrapper' id='control_wrapper'>
				<div className='control_wrapper'>
					<form id='formEvent' className="pd-1">
						<div className=''>
							<span className="my-custom-text form-field-label">
								{t('Date & Time In')}
							</span>
							<DateTimePickerComponent
								id='dateTimePickerIn'
								type='dateTime'
								enableMask={true}
								name='dateTimePickerIn'
								ref={userDateRefIn}
								value={inTime}
								change={(e) => {
									setInTime(e.value);
									//console.log(e.value);
								}}
								placeholder={t('Date & Time In')}
								floatLabelType='Never'
								data-msg-containerid='errorForDateTimeIn'
								className="form-control"
								cssClass="e-medium"
							></DateTimePickerComponent>
							<Inject services={[MaskedDateTime]} />
							<div id='errorForDateTimeIn' />
						</div>
						<div className='mt-2'>
							<span className="my-custom-text form-field-label">
							{t('Date & Time Out')}
							</span>
							<DateTimePickerComponent
								id='dateTimePickerOut'
								type='dateTime'
								ref={userDateRefOut}
								value={outTime}
								change={(e) => {
									setOutTime(e.value);
									//console.log(e.value);
								}}
								placeholder={t('Date & Time Out')}
								floatLabelType='Never'
								min={inTime}
								data-msg-containerid='errorForDateTimeOut'
								name='dateTimePickerOut'
								format='M/d/yyyy hh:mm a'
								className="form-control"
								cssClass="e-medium"
								enableMask={true}
							>
								<Inject services={[MaskedDateTime]} />
							</DateTimePickerComponent>
							<div id='errorForDateTimeOut' />
						</div>
						{inTime > outTime && (
							<div style={{ fontSize: "10px", color: "red", marginTop: ".5rem" }}>
								* {t("In date & time cannot be after out date & time")}
							</div>
						)}
					</form>
					{inTime && outTime && (
						<div className='submitBtn mt-2 my-custom-modal-footer'>
							{/* {JSON.stringify(inTime)} {JSON.stringify(outTime)} */}
							<button
								disabled={!inTime || !outTime || outTime < inTime}
								onClick={(e) => {
									onSubmitEvent();
									e.preventDefault();
								}}
								name='buttonSubmit'
								className="my-custom-btn"
								id='buttonSubmit'
							>
								{t("Submit")}
							</button>
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default InOut;

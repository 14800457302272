import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DateTimePickerComponent, MaskedDateTime, Inject } from "@syncfusion/ej2-react-calendars";
import { useEffect, useState } from "react";
import agent from "../../api/agent";
import { DataUtil } from "@syncfusion/ej2-data";
import TokenProvider from "../TokenProvider/TokenProvider";
import { useTranslation } from "react-i18next";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { extend } from '@syncfusion/ej2-base';

const ScheduledStopDialog = (props) => {
  const { t, i18n } = useTranslation();
  const [row, setRow] = useState();
  const { token } = TokenProvider();
  const now = new Date();
  const minutes = now.getMinutes();
  const nearestHalfHour = Math.round(minutes / 30) * 30;
  now.setMinutes(nearestHalfHour);
  const [delayReason, setDelayReason] = useState();

  // const [val, setval] = useState(extend({}, {}, props, true));
  // let data = val;
  // let appReqRef;
  // let appointmentDateRef;
  // let reasonCodeRef;
  // let confirmationNumberRef;
  // let confirmationNoteRef;

  useEffect(() => {

    var tempRow = Object.assign({}, props[0]);

    if(props && props[2]){
      let data = props[2].map(ele => {
        return { id: ele.id, description: t(ele.description) }
      })
      setDelayReason(data);
    }

    // if(tempRow?.reasonCode === null || tempRow?.reasonCode === undefined){
    //   let tenant = tempRow.tenant.toLowerCase();
    //   if(tenant === "primary"){
    //     tempRow.reasonCode = "NA"; // AL, NA
    //   }
    //   else if(tenant === "brokerage"){
    //     tempRow.reasonCode = "Z1";
    //   }
    //   else if(tenant === "ntd"){
    //     tempRow.reasonCode = "NS";
    //   }
    // }
    if(tempRow?.reasonCode === null || tempRow?.reasonCode === undefined){
      tempRow.reasonCode = "11";
    }
    // console.log("tempRow ", tempRow);
    setRow(tempRow);
  }, []);

  const onChange = (args) => {

    // if(!args){return}
    if(args?.target?.name === 'appReq') {
        setRow((prev) => ({ ...prev, [args.target.name]: !args.target.checked }));
    } else {
      setRow((prev) => ({ ...prev, [args.target.name]: args.target.value }));
    }
  };

  // function onChange(args) {
  //     if (args?.target?.name === 'appReq') {
  //     setval((prev) => ({ ...prev, [args.target.name]: !args.target.checked }));
  //   } else {
  //     setval((prev) => ({ ...prev, [args.target.name]: args.target.value }));
  //   }
  // }

  useEffect(() => {
   // if(!row){return}
    //console.log(row)
    const grid = props[1];
    const isAppReq = row?.appReq;
    const reasonCodeColumn = grid?.getColumnByField('reasonCode');
    const appointmentDate = grid?.getColumnByField('appointmentDate');

    if(row?.appBook){
      reasonCodeColumn.validationRules.required = true;
    } else {
      reasonCodeColumn.validationRules.required = false;
    }

    if (reasonCodeColumn) {
      reasonCodeColumn.validationRules.required = isAppReq;
      appointmentDate.validationRules.required = isAppReq;
   }

   if (isAppReq && !row?.appBook) {
     reasonCodeColumn.validationRules.required = false;
   }
  }, [row]);

  // console.log("row ", row);
  return (
    <div className="container-flex">
      <div className="control_wrapper" id="control_wrapper">
        <div className="control_wrapper" style={{ borderRadius: 0 }}>
          {row?.trip && (
            <div className="pd-1">
              {/* <p className="flex-center">
                {"row.appReq  " +(row.appReq? "true" : "false")}<br></br>
                {"row?.appBook  " +(row?.appBook? "true" : "false")}
                </p> */}
              <div className="form-group my-custom-text">
                {/* <CheckBoxComponent
                  floatLabelType="Always"
                  id="appReq"
                  name='appReq'
                  label={t("No Appointment Required")}
                  checked={!row?.appReq}
                  // value={!row?.appReq}
                  onChange={onChange.bind(this)}
                /> */}
                {/* <label style={{ margin: '2px' }}>
                  <input type="checkbox" id="appReq" name="appReq" checked={!row.appReq} onChange={(e) => onChange(e)} style={{ marginRight: '5px' }} />
                  {t("Appointment Not Required")}
                </label> */}
                <div className="e-checkbox-wrapper e-wrapper" style={{ margin: '2px' }}>
                  <label htmlFor="appReq" className="e-checkbox-label">
                      <input
                          id="appReq"
                          name="appReq"
                          className="e-control e-checkbox e-lib"
                          type="checkbox"
                          checked={!row.appReq}
                          onChange={(e) => onChange(e)}
                          style={{ marginRight: '5px' }}
                      />
                      <span className={"e-icons e-frame "+(!row.appReq && "e-check")}></span>
                      <span className="e-label">{t("Appointment Not Required")}</span>
                  </label>
                </div>
              </div>
              <div className="form-group mt-2">
                <span className="my-custom-text form-field-label">{t('Booking Date & Time')}</span>
                <DateTimePickerComponent
                  type="dateTime"
                  enabled={row?.appReq}
                  enableMask={true}
                  floatLabelType="Never"
                  // data-msg-containerid="errorForDateTimeIn"
                  className="form-control"
                  // floatLabelType="Always"
                  //onChange={onChange.bind(this)}
                  cssClass="e-medium"
                  placeholder={t('Select a date')}
                  id="appointmentDate"
                  name="appointmentDate"
                  value={row?.appointmentDate}
                  min={new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)}
                  max={new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)}
                  required={true}
                  strictMode={true}
                  showClearButton={false}
                  step={30}
                ></DateTimePickerComponent>
                <Inject services={[MaskedDateTime]} />
                {/* <div id="errorForDateTimeIn" /> */}
              </div>

              <div className="form-group my-custom-text mt-2" style={{ height: '55px' }}>
                <span className="form-field-label">{t('Reason Code')}</span>
                <DropDownListComponent
                  cssClass="e-medium multiline-dropdown"
                  enabled={row?.appReq}
                  fields={{ text: 'description', value: 'id' }}
                  dataSource={delayReason}
                  id="reasonCode"
                  name="reasonCode"
                  value={row?.reasonCode}
                  placeholder={t('Select a Reason Code')}
                  onChange={onChange.bind(this)}
                  opened={true}
                  itemTemplate={(data, index) => {
                    return (
                        <div style={{ whiteSpace:'pre-wrap', cursor:'pointer'}} title={data.description}>
                          {data.description}
                        </div>
                    );
                  }}
                />
              </div>
              {/* htmlAttributes={{ maxlength: 300 }} */}
              <div className="form-group my-custom-text mt-2" style={{ height: '55px' }}>
                <span className="form-field-label">{t('Confirmation Number')}</span>
                <TextBoxComponent
                  type="text"
                  enabled={row?.appReq}
                  floatLabelType="Never"
                  placeholder={t('Confirmation Number')}
                  id="confirmationNumber"
                  name="confirmationNumber"
                  onChange={onChange.bind(this)}
                  value={row?.confirmationNumber}
                  cssClass="e-medium"
                />
              </div>

              <div className="form-group my-custom-text mt-2" style={{ height: '55px' }}>
                <span className="form-field-label">{t('Confirmation Note')}</span>
                <TextBoxComponent
                  type="text"
                  cssClass="e-medium"
                  enabled={row?.appReq}
                  floatLabelType="Never"
                  placeholder={t('Confirmation Note')}
                  onChange={onChange.bind(this)}
                  id="confirmationNote"
                  name="confirmationNote"
                  value={row?.confirmationNote}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScheduledStopDialog;








  // return (
  //   <div className="container-flex">
  //     <div className="control_wrapper" id="control_wrapper">
  //       <div className="control_wrapper" style={{ borderRadius: 0 }}>
  //         {/* {row?.trip && ( */}
  //           <div className="pd-1">
  //             <p className="flex-center">
  //               {/* {"row.appReq  " +(row.appReq? "true" : "false")}<br></br>
  //               {"row?.appBook  " +(row?.appBook? "true" : "false")} */}
  //               </p>
  //             <div className="form-group my-custom-text">
  //               {/* <CheckBoxComponent
  //                 floatLabelType="Always"
  //                 id="appReq"
  //                 name="appReq"
  //                 ref={(check) => (appReqRef = check)}
  //                 label={'No Appointment Required'}
  //                 checked={!data?.appReq}
  //                 onChange={onChange.bind(this)}
  //               /> */}
  //               <label style={{ margin: '2px' }}>
  //                 <input type="checkbox" id="appReq" name="appReq" 
  //                 checked={!row.appReq} 
  //                 onChange={(e) => onChange(e)} 
  //                 style={{ marginRight: '5px' }} />
  //                 {t("Appointment Not Required")}
  //               </label>
  //             </div>
  //             <div className="form-group mt-2">
  //               <span className="my-custom-text form-field-label">{t('Booking Date & Time')}</span>
  //               <DateTimePickerComponent
  //                 type="dateTime"
  //                 enabled={data?.appReq}
  //                 enableMask={true}
  //                 floatLabelType="Never"
  //                 className="form-control"
  //                 cssClass="e-medium"
  //                 floatLabel={'Never'}
  //                 placeholder={t('Select a date')}
  //                 id="appointmentDate"
  //                 name="appointmentDate"
  //                 value={data?.appointmentDate}
  //                 min={new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000)}
  //                 max={new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)}
  //                 required={true}
  //                 strictMode={true}
  //                 showClearButton={false}
  //                 step={30}
  //               ></DateTimePickerComponent>
  //               <Inject services={[MaskedDateTime]} />
  //             </div>

  //             <div className="form-group my-custom-text mt-2" style={{ height: '55px' }}>
  //               <span className="form-field-label">{t('Reason Code')}</span>
  //               <DropDownListComponent
  //                 cssClass="e-medium"
  //                 enabled={data?.appReq}
  //                 fields={{ text: 'description', value: 'id' }}
  //                 dataSource={delayReason}
  //                 id="reasonCode"
  //                 name="reasonCode"
  //                 value={data?.reasonCode}
  //                 placeholder={t('Select a Reason Code')}
  //                 onChange={onChange.bind(this)}
  //               />
  //             </div>
  //             {/* htmlAttributes={{ maxlength: 300 }} */}
  //             <div className="form-group my-custom-text mt-2" style={{ height: '55px' }}>
  //               <span className="form-field-label">{t('Confirmation Number')}</span>
  //               <TextBoxComponent
  //                 type="text"
  //                 enabled={data?.appReq}
  //                 floatLabelType="Never"
  //                 placeholder={t('Confirmation Number')}
  //                 id="confirmationNumber"
  //                 name="confirmationNumber"
  //                 onChange={onChange.bind(this)}
  //                 value={data?.confirmationNumber}
  //                 cssClass="e-medium"
  //               />
  //             </div>

  //             <div className="form-group my-custom-text mt-2" style={{ height: '55px' }}>
  //               <span className="form-field-label">{t('Confirmation Note')}</span>
  //               <TextBoxComponent
  //                 type="text"
  //                 cssClass="e-medium"
  //                 enabled={data?.appReq}
  //                 floatLabelType="Never"
  //                 placeholder={t('Confirmation Note')}
  //                 onChange={onChange.bind(this)}
  //                 id="confirmationNote"
  //                 name="confirmationNote"
  //                 value={data?.confirmationNote}
  //               />
  //             </div>
  //           </div>
  //         {/* )} */}
  //       </div>
  //     </div>
  //   </div>
  // );
import "./Home.css";
import React from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import TokenProvider from "../TokenProvider/TokenProvider";
import { useState, useEffect, useRef } from "react";
import ThemeContext from "../Theme/ThemeContext";
import { BiFile } from "react-icons/bi"; // import icons
import { Card, Container } from "react-bootstrap";
import agent from "../../api/agent";

const Home = () => {
  const { token, setToken } = TokenProvider();
  const { t, i18n } = useTranslation();
  const { theme, toggleTheme } = useContext(ThemeContext);

  useEffect(() => {
    i18n.changeLanguage(token.locale);

    
  }, []);

  const handleFileDownload = (props) => {
    // Replace with your API endpoint URL
    
    if(props === "CapacityBoard") {
      agent.GetPDFCapacityGuide(token.token)
      .then((response) => {
        if (response.data) {
          const linkSource = `data:application/pdf;base64,${response.data}`;
          const downloadLink = document.createElement("a");
          const fileName = t(props) + ".pdf"; 
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          alert("Cannot download the file");
          throw new Error('Failed to fetch the file');
        }
      })
      .catch((error) => {
        alert("Cannot download the file");
        console.error('Error downloading the file:', error);
        // Handle error as needed
      });
    
    } else if (props === "TripManagement") {
      agent.GetPDFTripGuide(token.token)
      .then((response) => {
        if (response.data) {
          const linkSource = `data:application/pdf;base64,${response.data}`;
          const downloadLink = document.createElement("a");
          const fileName = t(props) + ".pdf"; 
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          alert("Cannot download the file");
          throw new Error('Failed to fetch the file');
        }
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
        // Handle error as needed
      });
    
    } else if (props === "ScheduledAppointment") {
      agent.GetPDFScheduleGuide(token.token)
      .then((response) => {
        if (response.data) {
          const linkSource = `data:application/pdf;base64,${response.data}`;
          const downloadLink = document.createElement("a");
          const fileName = t(props) + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        } else {
          alert("Cannot download the file");
          throw new Error('Failed to fetch the file');
        }
      })
      .catch((error) => {
        console.error('Error downloading the file:', error);
        // Handle error as needed
      });

    }
  };

  const FaqComponent = () => {

    const faqList = [
      {
        issue: "I entered a load in the Capacity Board, and it does not show up on the Trip Management page.",
        answer: "The customer needs to accept the load and update their systems. Only then a trip will be generated."
      },
      {
        issue: "I updated the ETA status for a trip. Nothing happens.",
        answer: "A notification with the update is sent to the customer and will not be reflected here."
      },
      {
        issue: "I received a cancellation email. Why can't I see the trip?",
        answer: "Cancelled trips are not shown. The trip was cancelled by the customer or replaced by another trip created in our system."
      },
      {
        issue: "The same loading slip is on multiple trips.",
        answer: "Please contact us at helpdesk@cedricmillar.com with the trip numbers."
      },
      {
        issue: "The weights are not correct for my trip.",
        answer: "Please contact us at helpdesk@cedricmillar.com with the trip numbers and correct weights."
      },
      {
        issue: "I need to cancel my trip.",
        answer: "Please contact the customer directly to request cancellation. Once updated in their systems, the trip will be cancelled in TMS."
      },
      {
        issue: "I am not sure what trip to invoice as I have received cancellations from active trips.",
        answer: "Please check carrier portal for loading slip number and match it to trip number for invoicing."
      }
    ];

    return (
      <div className="faq-container">
        <ul className="faq-list">
          {faqList.map((ele,idx)=>(
            <li key={idx}>
            <strong>{t('Issue')}:</strong> {t(ele.issue)}
            <br />
            <strong>{t('Answer')}:</strong> {t(ele.answer)}
          </li>
          ))}
        </ul>
      </div>
    );
  };

  //

  return (
    <Container fluid style={{ padding: "1.5rem", paddingBottom: "4rem" }}>
      <h1 className="display-2 mt-4 my-custom-text">
        {t("Welcome to Carrier Portal")}
      </h1>

      <h5 className="mt-4 my-custom-text">
        {t("Add capacity, manage trips and so much more!")}
      </h5>

      <div className="display-6 mt-4 my-custom-text">
        {t("Help Guides")}
      </div>
      <Card
        style={{
          marginTop: "2rem",
          width: "max-content",
          color: "black",
         // color: theme === "light" ? "black" : "white",
          background:"#ffd470",
        }}
      >
        <Card.Body>
          <div style={{ marginBottom: ".5rem" }}> 
               {t("Download File Capacity Board")} <BiFile onClick={()=>handleFileDownload("CapacityBoard")} /> 
          </div>

          <div style={{ marginBottom: ".5rem" }}> 
              {t("Download File Trip Management")}  <BiFile onClick={()=>handleFileDownload("TripManagement")} /> 
          </div>
          <div style={{ marginBottom: ".5rem" }}> 
               {t("Download File Scheduled Appointments")}  <BiFile onClick={()=>handleFileDownload("ScheduledAppointment")} /> 
          </div>
        </Card.Body>
      </Card>

      <div className="display-6 mt-4 my-custom-text">
        {" "}
        {t("FAQ's")}{" "}
      </div>
      <Card
        style={{
          marginTop: "2rem",
          width: "max-content",
          marginBottom: "1rem",
          color:"black",
          background: "#ffd470",
          overflow: "auto"
        }}
      >
        <Card.Body>
          <FaqComponent />
        </Card.Body>
      </Card>

      <div className="display-6 mt-4 my-custom-text">
        {" "}
        {t("Need help")}{" "}
      </div>
      <Card
        style={{
          marginTop: "2rem",
          width: "18rem",
          marginBottom: "1rem",
          color:"black",
          background: "#ffd470",
          overflow: "auto"
        }}
      >
        <Card.Body>
          {t("Contact Us")} <br /> <br />
          <small>
            Email:{" "}
            <a
              style={{ textDecoration: "none", color: "black" }}
              href="mailto:helpdesk@cedricmillar.com"
              target="_blank"
              rel="noreferrer"
            >
              {t("helpdesk@cedricmillar.com")}
            </a>
          </small>
        </Card.Body>
      </Card>
    </Container>
  );
};
export default Home;


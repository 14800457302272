import { useState } from "react";

// export const errorHandlingAxios = () =>{
// 	return null;
// }

export const logOutUser = () => {
	localStorage.removeItem("token");
	window.location.assign("/");
	setTimeout(() => {
		window.location.reload();
	}, 500);
}

export const getTokenUser = () => {
	return localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null;
}

export default function TokenProvider() {
	const getToken = () => {
		const tokenString = localStorage.getItem("token");
		const userToken = JSON.parse(tokenString);
		return userToken;
	};

	const [token, setToken] = useState(getToken());

	const saveToken = (userToken) => {
		localStorage.setItem("token", JSON.stringify(userToken));
		setToken(userToken);
	};

	return {
		setToken: saveToken,
		token,
	};
}

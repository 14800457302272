import React, { useEffect, useContext } from 'react';
import styles from './footer.module.css';
import { useTranslation } from 'react-i18next';
import TokenProvider from '../TokenProvider/TokenProvider';
import CMLogo from '../../images/Cedric-Millar-Logo-Horizontal-white.svg';
import ThemeContext from './../Theme/ThemeContext.js';

//TODO: make the links in to a global variable
const Footer = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const { token, setToken } = TokenProvider();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(token.locale);
  }, []);

  return (
    <footer className={styles.footer}>
      <div
        className={'container-flex'}
        style={{ display: 'flex', gap: '4rem', alignItems: 'flex-start' }}
      >
        {/* <img src={CMLogo} height={'30px'} style={{ marginLeft: '1rem' }} /> */}
        <label className={styles.label} style={{ margin: '10px' }}>
          &copy; {new Date().getFullYear()}&nbsp;
          {t('Cedric Millar Integrated Solutions')}
        </label>
        <label className={styles.label} style={{ margin: '10px',}}>
          <a
           style={{ textDecoration: "none", color: 'white' }}
            href="http://cedricmillar.com/privacy"
            target="_blank"
            rel="noreferrer"
          >
            {t('Privacy Policy')}
          </a>
        </label>
        <label className={styles.label} style={{ margin: '10px' }}>
          <a
          
            style={{ textDecoration: "none", color: 'white' }}
            href="http://cedricmillar.com/tos"
            target="_blank"
            rel="noreferrer"
          >
            {t('Terms of Service')}
          </a>
        </label>
      </div>
    </footer>
  );
};
export default Footer;

//TODO boarder
//blue selection navbar
//validate as type

import React from 'react';
import agent from "../../api/agent";
import TokenProvider, {getTokenUser} from "../TokenProvider/TokenProvider";
import { withTranslation } from 'react-i18next';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      compName: (props.compName ? props.compName : ""),
      token: getTokenUser(),
    };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      ...this.state,
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
    console.error("in Component :", this.state.compName, " The error : ",error, errorInfo);
    // this.postEmailError(error, errorInfo);
  }

  postEmailError = (error, errorInfo) => {
    agent.sendErrorEmail({
      token: this.state.token?.token,
      EmailSubject:"Error in Carrier Portal Frontend",
      EmailBody: `Error: ${error && error.toString()}
        ------------------------------------------------
        ErrorEnfo: ${errorInfo && errorInfo.componentStack}`
    })
    .then((response) => {
        // if (response.data) {
          window.location.reload();
        // }
      })
    .catch((error) => {
      console.error('Error while reporting the error:', error);
      window.location.reload();
    });
  };

  render() {
    const { t } = this.props;

    if (this.state.hasError) {
      return (
        <div className='my-custom-text'>
          <h2>{t('Something went wrong.')}</h2>
          {/* <p>{this.state.error && this.state.error.toString()}</p>
          <p>{this.state.errorInfo && this.state.errorInfo.componentStack}</p> */}
        </div>
      );
    }
    // Render the child components if no error occurred
    return this.props.children;
  }
}

export default withTranslation()(ErrorBoundary);

import axios from "axios";
import FormData from "form-data";
import { logOutUser } from "../components/TokenProvider/TokenProvider";

//const API_URL = "https://uatrestapi.cedricmillar.com";
//const API_URL = "https://restapi.cedricmillar.com";
const API_URL = process.env.REACT_APP_API;
const requestType = 1;
const username = process.env.REACT_APP_API_USERNAME;
const password = process.env.REACT_APP_API_PASSWORD;

export const USERMGMT_URL = process.env.REACT_APP_USERMANAGEMENT_URL;

const auth = {
	auth: {
		username: username,
		password: password,
	},
};

// Add an interceptor to handle response errors
axios.interceptors.response.use(
	(response) => response,
	(error) => {
	  if (!error.response) {
		// No response from the server
		logOutUser();
	  }
	  return Promise.reject(error);
	}
);

const fetchToken = async (username, password, requestSource, deviceIdentifier) => {
	return axios
		.get(
			`${API_URL}/GetLoginValidationV2?requestSource=${requestSource}&userName=${username}&password=${password}&deviceIdentifier=${deviceIdentifier}`,
			auth
		)
		.catch((error) => console.log(error));
};

const GetScheduledAppointments = async (token) => {
	return axios
		.get(
			`${API_URL}/GetScheduledAppointments?token=${token}`,
			auth
		)
		.catch((error) => console.log(error));
};


const PostThemeCarrierPortal = async (token, userID, theme, lang) => {
	// console.log(auth);
	return axios.post(
	  `${API_URL}/PostThemeCarrierPortal?token=${token}&userID=${userID}&theme=${theme}&lang=${lang}`,
	  "",
	  auth
	);
}

const fetchEquipmentList = async () => {
	return axios.get(`${API_URL}/GetEquipmentList?requestType=${requestType}`, auth);
};

const fetchStateList = async () => {
	return axios.get(`${API_URL}/GetStateList`, auth);
};

const fetchEquipmentHistory = async (token) => {
	return axios.get(`${API_URL}/GetAvailableCapacityByUser?accessToken=${token}`, auth);
};

const postCapacity = async (
	accessToken,
	equipmentID,
	equipAvailDate,
	qty,
	oState,
	oArea,
	dState,
	dArea,
	comments,
	enteredBy
) => {
	return axios.post(
		`${API_URL}/PostCapacity`,
		{
			accessToken: accessToken,
			comments: comments,
			dArea: dArea,
			dState: dState,
			enteredBy: enteredBy,
			equipAvailDate: equipAvailDate,
			equipmentID: equipmentID,
			oArea: oArea,
			oState: oState,
			qty: qty,
		},
		auth
	);
};

const deleteCapacity = async (accessToken, capacityId, enteredBy) => {
	return axios.delete(
		`${API_URL}/DeleteCapacity?accessToken=${accessToken}&capacityId=${capacityId}&deletedBy=${enteredBy}`,
		auth
	);
};

const fetchDefaults = async (accessToken, source) => {
	return axios.get(`${API_URL}/GetDefaultsByUser?accessToken=${accessToken}&source=${source}`, auth);
};

const fetchOpenTrips = async (accessToken) => {
	return axios.get(`${API_URL}/GetOpenTrips_Tender?accessToken=${accessToken}`, auth);
};

const fetchTrip = async (accessToken, tripID, tenant) => {
	return axios.get(`${API_URL}/GetTrip?accessToken=${accessToken}&tripID=${tripID}&tenant=${tenant} `, auth);
};

const fetchTripDetailsByTripID = async (accessToken, tripNumber, tenant) => {
	return axios.get(
		`${API_URL}/GetOpenTripsDetails?accessToken=${accessToken}&tripID=${tripNumber}&tenant=${tenant}`,
		auth
	);
};


const postEvent = async (
	accessToken,
	tripNumber,
	arrivedDateTime,
	timeZone,
	sequence,
	delayReason,
	comment,
	file,
	enteredBy,
	eventType,
	tenant,
	stopTypeEnum
) => {
	//console.log(file);
	const formData = new FormData();
	formData.append("EventType", eventType);
	formData.append("PDF", file);
	formData.append("AccessToken", accessToken);
	formData.append("TripID", tripNumber);
	formData.append("EventDateTime", arrivedDateTime);
	formData.append("TimeZone", timeZone);
	formData.append("Sequence", sequence);
	formData.append("DelayReason", delayReason);
	formData.append("Comment", comment);
	formData.append("EnteredBy", enteredBy);
	formData.append("Tenant", tenant);
	formData.append("StopTypeEnum", stopTypeEnum);

	return axios.post(`${API_URL}/PostUpdateEvent`, formData, {
		auth: {
			username: username,
			password: password,
		},
	});
};

const PostEventInOut = async (
	accessToken,
	tripNumber,
	inTime,
	outTime,
	sequence,
	enteredBy,
	eventType,
	stopTypeEnum,
	tenant
) => {
	//console.log(file);
	const formData = new FormData();
	formData.append("AccessToken", accessToken);
	//formData.append("CarrierID", JSON.stringify(carrierID));
	formData.append("TripID", tripNumber);
	formData.append("InTime", inTime);
	formData.append("OutTime", outTime);
	formData.append("Sequence", sequence);
	formData.append("EnteredBy", enteredBy);
	formData.append("EventType", eventType);
	formData.append("StopTypeEnum", stopTypeEnum);
	formData.append("Tenant", tenant);

	console.log(inTime, outTime, eventType, "asd");

	return axios.post(`${API_URL}/PostUpdateEventInOut`, formData, {
		auth: {
			username: username,
			password: password,
		},
	});
};

const PostDocument = async (accessToken, tripNumber, sequence, file, enteredBy, tenant) => {
	//console.log(file);
	const formData = new FormData();
	formData.append("PDF", file);
	formData.append("AccessToken", accessToken);
	formData.append("TripID", tripNumber);
	formData.append("Sequence", sequence);
	formData.append("EnteredBy", enteredBy);
	formData.append("Tenant", tenant);

	return axios.post(`${API_URL}/PostDocument`, formData, {
		auth: {
			username: username,
			password: password,
		},
	});
};

const fetchDelayReason = async (accessToken) => {
	return axios.get(`${API_URL}/GetDelayReason?accessToken=${accessToken}`, auth);
};

const getTenderConfirmationPDF = async (accessToken, tripID, carrierID, tenant) => {
	return axios.get(
		`${API_URL}/GetTenderConfirmation?accessToken=${accessToken}&tripID=${tripID}&carrierID=${carrierID}&tenant=${tenant}`,
		{
			auth: {
				username: username,
				password: password,
			},
		}
	);
};

const GetDocument = async (accessToken, tripID, seq) => {
	return axios.get(`${API_URL}/GetDocument?accessToken=${accessToken}&tripID=${tripID}&seq=${seq}`, {
		auth: {
			username: username,
			password: password,
		},
	});
};

const sendErrorEmail = async (bodyData) => {
	return await axios.post(`${API_URL}/sendEmailErrorCarrierPortal`, bodyData, auth);
};

//

const GetPDFCapacityGuide = async (accessToken) => {
	return axios.get(`${API_URL}/api/v3/Documents/GetCapacityPDF?accessToken=${accessToken}`, {
		auth: {
			username: username,
			password: password,
		},
	});
};

const GetPDFScheduleGuide = async (accessToken) => {
	return axios.get(`${API_URL}/api/v3/Documents/GetPDFScheduleGuide?accessToken=${accessToken}`, {
		auth: {
			username: username,
			password: password,
		},
	});
};

const GetPDFTripGuide = async (accessToken) => {
	return axios.get(`${API_URL}/api/v3/Documents/GetPDFTripGuide?accessToken=${accessToken}`, {
		auth: {
			username: username,
			password: password,
		},
	});
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
	fetchEquipmentList,
	fetchToken,
	PostThemeCarrierPortal,
	fetchStateList,
	fetchEquipmentHistory,
	postCapacity,
	deleteCapacity,
	fetchOpenTrips,
	fetchTrip,
	fetchTripDetailsByTripID,
	fetchDefaults,
	postEvent,
	fetchDelayReason,
	getTenderConfirmationPDF,
	GetDocument,
	PostEventInOut,
	PostDocument,
	sendErrorEmail,
	GetScheduledAppointments, 
	GetPDFCapacityGuide,
	GetPDFTripGuide,
	GetPDFScheduleGuide,
};

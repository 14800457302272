import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { L10n } from '@syncfusion/ej2-base';
import { loadCldr } from '@syncfusion/ej2-base';
import * as gregorian from './ca-gregorian.json';
import * as currencies from './currencies.json';
import * as numbers from './numbers.json';
import * as timeZoneNames from './timeZones.json';
import fr from './fr.json';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        //English translations here
      },
    },
    fr: {
      translation: {
        //French
        Subject: 'Sujet',
        Logout: 'Se déconnecter',
        Home: 'Accueil',
        Privacy: 'Confidentialité',
        Help: 'Aide',
        'Cedric Millar Integrated Solutions':
          ' Cedric Millar Solutions Intégrées',
        'Add Event': 'Ajouter un évènement',
        'Need Help?': "Besoin d'aide?",
        Login: 'Connexion',
        'Trip Management': 'Gestion des voyages',
        'Capacity Board': 'Tableau des capacités',
        'New Capacity Location': 'Nouvel emplacement de capacité',
        'Equipment Type': "Type d'équipement",
        'Equipment<br>Type': "Type<br>d'équipement",
        Dark: "Sombre",
        Light: "Clair",
        'Add capacity, manage trips and so much more!': 'Ajouter de la capacité, gérer les voyages et biens plus encore!',
        "FAQ's": "Questions fréquemment posées (FAQ)",
        "Download File Capacity Board": "Télécharger le fichier Tableau des capacités",
        "Download File Trip Management": "Télécharger le fichier Gestion des voyages",
        "Need help": "Besoin d'aide",
        "Contact Us": "Contactez-nous",
        "Email": "Courriel",
        "Assigned Trips": "Voyages attribués",
        "Appointments": "Rendez-vous",
        "Last Refreshed": "Dernière actualisation",
        "Refresh": "Actualiser",
        "Refreshing...": "Rafraîchissant…",
        "No records to display": "Aucun enregistrement à afficher",
        "Past 30 Day Capacity Locations": "Emplacements de capacité des 30 derniers jours",
        "From": "De",
        "Pickup City/Area": "Ville/Zone de ramassage",
        "Qty": "Quantité",
        "To": "À",
        "Destination City/Area": "Ville/Zone de destination",
        "Equipment Available Date": "Date de disponibilité de l'équipement",
        "Comments": "Commentaires",
        "Comment": "Commentaire",
        "Reset": "Réinitialiser",
        "Submit": "Soumettre",
        "Update In/Out": "Mettre à jour l'entrée/sortie",
        "Add Document": "Ajouter un document",
        "Replace Document": "Remplacer le document",
        "Select Option": "Sélectionner une option",
        "Add or Replace Document": "Ajouter ou remplacer un document",
        "Date & Time In": "Date et heure d'entrée",
        "Date & Time Out": "Date et heure de sortie",
        "Proof Of Delivery PDF": "Preuve de livraison PDF",
        "Browse": "Parcourir",
        "Or drop files here": "Ou déposer les fichiers ici",
        "ETA": "ETA",
        "ETA Update": "ETA Mise à jour",
        "Arrived": "Arrivé",
        "Appointment Update": "Mise à jour de rendez-vous",
        "Appointment Not Required":"Pas de rendez-vous requis",
        "Pickup": "Ramassage",
        "Cross-Dock Arrival": "Arrivée en cross-docking",
        "Unable to get the Trip Confirmation.": "Impossible d'obtenir la confirmation du voyage.",
        // for main look of page

        // appointment reason delays dropown
        "Missed Delivery - Carrier Related": "Livraison manquée - Liée au transporteur",
        "Access Restriction -  Blockage or Construction": "Restriction d'accès - Blocage ou construction",
        "Unable to Locate": "Impossible de localiser",
        "Accident - Carrier Related": "Accident - Lié au transporteur",
        "Consignee Related - Refusal or Delay": "Destinataire lié - Refus ou retard",
        "Driver Related": "Lié au conducteur",
        "Mechanical Breakdown - Trailer- Carrier Related": "Panne mécanique - Remorque - Lié au transporteur",
        "Previous Stop Delay - Carrier Related": "Retard à l'arrêt précédent - Lié au transporteur",
        "Past Cut-off Time- Carrier Related": "Après l'heure limite - Lié au transporteur",
        "Missed Pick-up - Carrier Related": "Ramassage manqué - Lié au transporteur",
        "Improper Equipment - Carrier Related": "Équipement inapproprié - Lié au transporteur",
        "Driver delay no FOB": "Retard du conducteur sans FOB",
        "Insufficient Time to Complete Delivery": "Temps insuffisant pour effectuer la livraison",
        "Shipment Overweight - Carrier Related": "Surcharge d'expédition - Lié au transporteur",
        "Carrier Dispatch Error-Carrier Related": "Erreur de répartition du transporteur - Lié au transporteur",
        "Driver Not Available - Carrier Related": "Chauffeur non disponible - Lié au transporteur",
        "Equipment Non-Compliance": "Non-conformité de l'équipement",
        "Held Pending Appointment - Carrier Related": "En attente de rendez-vous - Lié au transporteur",
        "Drivers Hours of Service exceeded - Carrier Related": "Heures de service des conducteurs dépassées - Lié au transporteur",
        "Carrier caused Linehaul Delay - Carrier Related": "Retard de transport provoqué par le transporteur - Lié au transporteur",
        "Mechanical Breakdown - Tractor - Carrier Related": "Panne mécanique - Tracteur - Lié au transporteur",
        "Missing documents - Carrier Related": "Documents manquants - Lié au transporteur",
        "Carrier caused Load Shift": "Déplacement de charge provoqué par le transporteur",
        "Appointment Modified":"Rendez-vous modifié",
        "Appointment Required Changed":"Rendez-vous requis changé",
        "Normal Appointment":"Rendez-vous normal",
        // for capacity board
        "Please enter in a date": "Veuillez entrer une date",
        "Please select a truck": "Veuillez sélectionner un camion",
        "Please enter a qty": "Veuillez entrer une quantité",
        'Your session has expired. Please login again.': 'Votre session a expiré. Veuillez vous reconnecter.',
        "Please select the equipment type again": "Veuillez sélectionner à nouveau le type d'équipement",
        "Unable to post capacity. Please refresh the page and check your internet connection.": "Impossible de publier la capacité. Veuillez rafraîchir la page et vérifier votre connexion Internet.",
        "Unable to delete. Please check your internet connection. If the error continues, please contact the Help Desk": "Impossible de supprimer. Veuillez vérifier votre connexion Internet. Si l'erreur persiste, veuillez contacter le service d'assistance",

        "Unable to update event status. Please check your internet connection": "Impossible de mettre à jour le statut de l'événement. Veuillez vérifier votre connexion Internet.",
        "Unable to add event status, please login again": "Impossible d'ajouter le statut de l'événement, veuillez vous reconnecter.",
        "Unable to add event status.": "Impossible d'ajouter le statut de l'événement.",
        "Unable to add event status. Error:": "Impossible d'ajouter le statut de l'événement. Erreur :",
        "In date & time cannot be after out date & time": "La date et l'heure d'entrée ne peuvent pas être postérieures à la date et l'heure de sortie",
        "Error": "Erreur",
        "Please enter in a date and time": "Veuillez entrer une date et une heure",
        "Driver release from consignee (trip will be shown as delivered automatically on the last stop)": "Autorisation du destinataire par le conducteur (le trajet sera automatiquement marqué comme livré sur le dernier arrêt)",
        "ETA will send notificaiton to customer and will not be reflected here": "L'heure estimée d'arrivée enverra une notification au client et ne sera pas affichée ici",
        "Time of arrival at consignee": "Heure d'arrivée chez le destinataire",
        "Date & Time": "Date et heure",
        "Delay Reason": "Raison du retard",


        "Issue": "Problème",
        "Answer": "Réponse",
        "Truck Type": "Type de camion",
        "State/Province": "État/Province",
        "Quantity": "Quantité",
        "Copy": "Copier",
        "Delete": "Supprimer",
        "Equipment": "Équipement",
        "Type": "Type",
        "Available": "Disponible",
        "Date": "Date",
        "Enter date": "Entrez la date",

        // for trip management
        "Terms of Service": "Conditions d'utilisation",
        "Departed": "Départ",
        "Trip#": "Numéro de trajet",
        "Ref#": "Réf.",
        "Sch. First<br>P/U": "Première prise<br>en charge planifiée",
        "Sch. Del<br>Date": "Date de livraison<br>planifiée",
        "Open and<br>Close Time": "Heure d'ouverture<br>et de fermeture",
        "Sch. Date": "Date planifiée",
        "P/U Date": "Date de ramassage",
        "First P/U": "Premier ramassage",
        "Del Date": "Date de livraison",
        "Last Drop": "Dernière livraison",
        "Stops": "Arrêts",
        "Stop":"Arrêt",
        "Mode": "Mode",
        "Weight": "Poids",
        "Weight<br />(lb)": "Poids<br />(lb)",
        "Miles": "Miles",
        "Download": "Télécharger",
        "Seqeunce": "Séquence",
        "Trip": "Trajet",
        "Stop #": "Arrêt n°",
        "Shipment Status": "Statut de l'envoi",
        "Address": "Adresse",
        "ZIP Code": "Code postal",
        "Instructions": "Instructions",
        "Appt Req": "Demande de rendez-vous",

        // book appointment
        "Trip<br>#": "Numéro<br>de voyage",
        "Order<br>Ref":"Référence<br>de commande",
        "Stop<br>Sequence": "Séquence<br>d'arrêt",
        "Stop Name": "Nom de l'arrêt", "Stop<br>Name": "Nom de<br>l'arrêt",
        "Province": "Province",
        "Postal Code": "Code postal", "Postal<br>Code": "Code<br>postal",
        "Appointment<br>Book": "Rendez-vous<br>Réserver",
        "Confirmation<br>Number": "Numéro de<br>confirmation",
        "Confirmation<br>Note": "Note de<br>confirmation",
        "Appointment<br>Status": "Statut de<br>rendez-vous",
        "Select a date": "Sélectionner une date",
        "Booking Date & Time": "Date et heure de réservation",
        "Appointment Required": "Rendez-vous requis",
        "Reason Code": "Code de motif",
        "Select a Reason Code": "Sélectionner un code de motif",
        "Confirmation Note": "Note de confirmation",

        /// FAQ's
        "I entered a load in the Capacity Board, and it does not show up on the Trip Management page.": "J'ai saisi un chargement dans le tableau des capacités et il n'apparaît pas sur la page de gestion des voyages.",
        "The customer needs to accept the load and update their systems. Only then a trip will be generated.": "Le client doit accepter le chargement et mettre à jour son système. Ce n'est qu'à ce moment-là qu'un voyage sera généré.",
        "I updated the ETA status for a trip. Nothing happens.": "J'ai mis à jour le statut (Heure probable d'arrivée / ETA) d'un déplacement. Rien ne semble se passer.",
        "A notification with the update is sent to the customer and will not be reflected here.": "Une notification avec la mise à jour est envoyée au client et ne sera pas affichée ici.",
        "I received a cancellation email. Why can't I see the trip?": "J'ai reçu un courriel d'annulation : Pourquoi ne puis-je pas voir le voyage?",
        "Cancelled trips are not shown. The trip was cancelled by the customer or replaced by another trip created in our system.": "Les voyages annulés ne sont pas affichés. Le voyage a été annulé par le client ou remplacé par un autre voyage créé dans notre système.",
        "The same loading slip is on multiple trips.": "Le même bon de chargement figure sur plusieurs voyages.",
        "Please contact us at helpdesk@cedricmillar.com with the trip numbers.": "Veuillez nous contacter à l'adresse helpdesk@cedricmillar.com en indiquant les numéros de voyage.",
        "The weights are not correct for my trip.": "Les poids sont incorrects pour mon voyage.",
        "Please contact us at helpdesk@cedricmillar.com with the trip numbers and correct weights.": "Veuillez nous contacter à l'adresse helpdesk@cedricmillar.com avec les numéros de voyage et les poids adéquats.",
        "I need to cancel my trip.": "Je dois annuler mon voyage.",
        "Please contact the customer directly to request cancellation. Once updated in their systems, the trip will be cancelled in TMS.": "Veuillez contacter le client directement pour demander l'annulation. Une fois mis à jour dans leurs systèmes, le voyage sera annulé dans notre système de gestion des transports (TMS).",
        "I am not sure what trip to invoice as I have received cancellations from active trips.": "Je ne sais pas quel voyage facturer, car j'ai reçu des annulations de voyages actifs.",
        "Please check carrier portal for loading slip number and match it to trip number for invoicing.": "Veuillez consulter le portail du transporteur pour obtenir le numéro de bordereau de chargement et le faire correspondre au numéro de voyage pour la facturation.",

        ////
        'Welcome to Carrier Portal': "Bienvenue sur le Portail des Transporteurs",
        'Help Guides': "Guides d'aide",
        'Contact us': 'Nous contacter',
        Schedule: 'Calendrier',
        'Excel Export': 'Exportation Excel',
        //06/06
        'New Event': 'Nouvel évènement',
        Type: 'Type',
        'Choose event type': "Choisissez le type d'événementR",
        Door: 'Porte',
        'Select a door': 'Sélectionnez une porte',
        Time: 'Temps',
        Start: 'Début',
        End: 'Fin',
        'Override Freight Profile': 'Remplacer le profil de cargaison',
        'Total Event Minutes': "nombre total de minutes d'événement",
        'All Day Event': 'Événements toute la journée',
        'Block Other Events': "Bloquer d'autres événements",
        'Expiring Session...': 'Session expirante...',
        'Your session is about to expire. Please logout.':
          "Votre session est sur le point d'expirer. Veuillez vous déconnecter.",
        'Your login sessions has expired. Please Logout.':
          'Votre session de connexion a expiré. Veuillez vous déconnecter.',
        Password: 'Le mot de passe',
        'Incorrect credentials. Please try again.':
          'Identifiants incorrects. Veuillez réessayer.',
        'Email Us': 'Envoyez-nous un email',
        Username: "Nom d'utilisateur",
        'Unable to reach the server. Please check your internet connection.':
          "Impossible d'atteindre le serveur. S'il vous plait, vérifiez votre connexion internet. FR",
        CANCELLED: 'ANNULER',
        Cancel: 'Annuler',
        'Choose cancel reason': "Choisissez la raison de l'annulation",
        Status: 'Statut',
        'Choose a status': 'Choisissez un statut',
        'Enter event details': "Entrez les détails de l'événement",
        '*Possible conflict. Please choose another door or time':
          '*Possibilité de conflit. Veuillez choisir une autre porte ou heure',
        Carrier: 'Transporteur',
        'Choose a carrier': 'Choisissez un transporteur',
        'Freight Profile': 'Profil de la cargaison',
        Notes: 'Nota',
        'Add notes': 'Ajouter des remarques',
        Add: 'Ajouter',
        Edit: 'Éditer',
        Delete: 'Supprimer',
        Update: 'Mise à jour',
        'Actual Time': 'Temps réel',
        'End Actual': 'Réel de fin',
        'Ref #': 'Réf #',
        'Carrier Reference #': 'Référence du transporteur',
        'Acpt Date': "Date d'acceptation",
        'Carrier Accepted Date': "Date d'acceptation du transporteur",
        'Cst. Ref #': 'Clt. Réf',
        'Customer Reference #': 'Numéro de référence client',
        'Carrier Instr': 'Consignes du transporteur',
        'Carrier Instructions': 'Instructions du transporteur',
        'Seal #': 'Scellé#',
        'Trailer #': 'Caravane # FR',
        'Insp Status': "Statut d'inspec",
        'Inspection Staus': "Statut d'inspection",
        Temp: 'Temp',
        degree: 'Degré',
        Actual: 'Réelle', //temperature
        'Please select a carrier': 'Veuillez sélectionner un transporteur',
        Save: 'Sauver',
        Conflicts: 'Conflits',
        'Actual Quantity': 'Quantité réelle',
        'Total Units': 'Unités totales',
        Name: 'Nom',
        'CMIS Udpate': 'CMIS Mettre au courant',
        'Your event has been updated.': 'Votre événement a été mis à jour.',
        'Event Created': 'Événement créé',
        'Event Updated': 'Événement mis à jour',

        'Please enter date in mm/dd/yyy format':
          'Veuillez saisir la date au format mm/jj/aaaa',
        History: 'Histoire',
        'Calculated Minutes': 'Minutes calculées',
        'There was an error processing the request':
          "Une erreur s'est produite lors du traitement de la demande",
        'Password/Password FR': 'Password/Mot de passe ',
        'Username/Username FR': "Username/Nom d'utilisateur",
        'Incorrect username and/or password.':
          "Nom d'utilisateur et/ou mot de passe incorrect.",
        'Add Vendor': 'Ajouter un fournisseur',
        Vendor: 'Vendeur',
        'Confirmation Number': 'Numéro de confirmationr',
        ' for ': ' pour',
        'Internal Notes': 'Remarques internes',
        'Expected Temperature': 'Température attendue',
        'Actual Temperatre': 'Température réelle',
        ' Reference:': 'Référence:',
        'Customer REF:': 'Client REF:',
        Pickup: 'Ramassage',
        "Cross-Dock Departure": "Départ en Cross-Dock",
        Cancelled: 'Annulé',
        Delivered: 'Livré',
        Delivery: 'Livraison',
        Scheduled: 'Calendrier',
        'No Show': "N'est pas arrivé",
        'Customer Reference': 'Référence client',
        'Customer Confirmation Number': 'Numéro de confirmation client',
        'Site Administration': 'Administration du site',
        Profile: 'Profil',
        Reporting: 'Rapport',
        'There are unsaved changes. Please save or cancel.':
          'Il y a des modifications non enregistrées. Veuillez enregistrer ou annuler.',
        Search: 'Rechercher',
        Admin: 'Administrateur',
        'Edit Event': "Modifier l'événement",
        Email: 'Courriel',
        'First Name': 'Prénom',
        'Last Name': 'nom de famille',
        Locale: 'Lieu',
        View: 'Voir',
        Remove: 'Retirer',
        'Reset Password': 'Réinitialiser le mot de passe',
        'Choose Existing User': 'Choisissez un utilisateur existant',
        'Add Existing User': 'Ajouter un utilisateur existant',
        'User Permissions': 'Autorisation utilisateur',
        'Select Close Time': "Sélectionnez l'heure de fermeture",
        Site: 'Site',
        'Site Description': 'Description du site',
        'Site Name': 'Site name',
        'Booking Interval (m)': 'Intervalle de réservation (m)',
        'Street Address': 'Adresse de rue',
        City: 'Ville',
        State: 'État (ou province)',
        'Select Open Time': "Sélectionner l'heure d'ouverture",
        'Site email': 'E-mail du site',
        'Show Temperature': 'Afficher la température',
        Inactive: 'Inactif',
        Timezone: 'Fuseau Horaire',
        Country: 'Pays',
        Color: 'Couleur',
        Filter: 'Filtre',
        'Reference #': 'Référence #',
        'Customer Ref #': 'Réf client #',
        'Accepted On': 'Accepté le',
        'Search Page By Field': 'Page de recherche',
        'Re- currence Editor': 'Éditeur de récurrence',
        ADD: 'Ajouter',
        'To create a freight profile, please go to Site Managment':
          'Pour créer un profil de fret, veuillez vous rendre sur la gestion du site',
        ' Vendor ': 'Vendeur',
        '- Customer Reference ': '- Référence client',
        'Door Name': 'Nom de la porte',
        Label: 'Étiquette',
        Close: 'Fermer',
        'Add Existing Carrier': 'Ajouter un opérateur existant',
        'Choose Existing Carrier': 'Choisissez un opérateur existant',
        Cell: 'Téléphone ',
        Department: 'Département',
        Description: 'Description',
        'Maximum Minutes': 'Minutes maximales',
        'This field is required': 'Ce champ est requis',
        'Add Existing Freight Profile':
          'Ajouter un profil de cargaison existant',
        Completed: 'Complété',
        Assigned: "Assigné",
        Delivered:"Livré",
        Enroute: "En route",
        'Inspection Status': "Statut d'inspection",
        Hold: 'Tenir',
        Pass: 'Passer',
        'Include Recurrence': 'Inclure la récurrence',
        Clear: 'Réinitialiser',
        'Vendor Name': 'Nom du Vendeur',
        'Schedule End': "Fin de l'horaire",
        'Schedule Start': "Début de l'horaire",
        Results: 'Résultats',
        'No Results': 'aucun résultat',
        'No doors available. Add a door.':
          'Aucune porte disponible. Ajouter une porte.',
        'No carriers available. Add a carrier.':
          'Aucun support disponible. Ajoutez un transporteur.',
        'Run Report': "Rapport d'exécution",
        'Report Name': 'Nom du rapport',
        New: 'Nouveau',
        'Unsaved changes...': 'Modifications non enregistrées...',
        'Event Status': "Statut de l'événement",
        'End Date': 'Date de fin',
        'Start Date': 'Date de début',
        'Event Type': "Type d'événement",
        'Please update your password':
          'Veuillez mettre à jour votre mot de passe',
        'Recurrence Editor': 'Éditeur de récurrence',
        'Add Door': 'Ajouter une porte',
        'Choose Existing Freight Profile':
          'Choisissez le profil de cargaison existant',
        'Please add a vendor through Site Management':
          'Veuillez ajouter un fournisseur via la gestion du site',
        'A TRUSTED APPROACH TO SUPPLY CHAIN SOLUTIONS':
          "UNE APPROCHE DE CONFIANCE POUR LES SOLUTIONS DE CHAÎNE D'APPROVISIONNEMENT",
        'By logging in, you agree to our':
          'En vous connectant, vous acceptez nos',
        'Privacy Policy': 'politique de confidentialité',
        and: 'et',
        'Terms of Use': "nos conditions d'utilisation",
        'LOG IN': 'CONNEXION',
        'Need Assistance?': "Besoin d'assistance?",
        'Contact us here': 'Contactez-nous ici',
        'Moved By Intelligence': 'Mû par intelligence',
        'New Password': 'Nouveau mot de passe',
        'Confirm Password': 'Confirmez le mot de passe',
      },
    },
  },
  lng: 'en',
  fallbackLng: 'en',
});

// L10n.load({
//   'fr-FR': {
//     datepicker: {
//       today: "Aujourd'hui"
//     },
//     dialog: {
//       close: 'Fermer'
//     },
//     daterangepicker: {
//       applyText: 'Appliquer',
//       cancelText: 'Annuler',
//       "customRange": "Plage personnalisée",
//       "days": "Jours"
//     },
//     numerictextbox:{
//       incrementTitle:"Augmenter la valeur",
//       decrementTitle:"Diminuer la valeur"
//     },
//     'grid': {
//       Empty: 'No hay registros que mostrar',
//       EmptyRecord: 'Aucun enregistrement à afficher',
//       InvalidFilterMessage: 'Datos de filtro inválidos',
//       GroupDropArea:
//         'Arrastra un encabezado de columna aquí para agrupar su columna',
//       UnGroup: 'Haga clic aquí para desagrupar',
//       GroupDisable: 'La agrupación está deshabilitada para esta columna',
//       FilterbarTitle: 'celda de barra de filtro',
//       EmptyDataSourceError:
//         'DataSource no debe estar vacío en la carga inicial ya que las columnas se generan desde dataSource en la Cuadrícula de columnas de AutoGenerate',
//       Print: 'Impresión',
//       PdfExport: 'Exportar a PDF',
//       WordExport: 'Exportar a Word',
//       CsvExport: 'Exportar a CSV',
//       Search: 'Buscar',
//       Columnchooser: 'Columnas',
//       Save: 'Grabar',
//       Item: 'registro',
//       Items: 'registros',
//       EditOperationAlert:
//         'No hay registros seleccionados para la operación de edición',
//       DeleteOperationAlert:
//         'No hay registros seleccionados para la operación de eliminación',
//       SaveButton: 'Grabar',
//       OKButton: 'Confirmar',
//       CancelButton: 'Cancelar',
//       EditFormTitle: 'Detalles de',
//       AddFormTitle: 'Añadir nuevo registro',
//       BatchSaveConfirm: '¿Seguro que quieres guardar los cambios?',
//       BatchSaveLostChanges:
//         'Los cambios no guardados se perderán. Estás seguro de que quieres continuar?',
//       ConfirmDelete: '¿Seguro que quieres borrar el registro?',
//       CancelEdit: '¿Seguro que quieres cancelar los cambios?',
//       ChooseColumns: 'Elija Columna',
//       SearchColumns: 'columnas de búsqueda',
//       Matchs: 'No se encontraron coincidencias',
//       FilterButton: 'Filtrar',
//       ClearButton: 'Cancelar',
//       StartsWith: 'Comienza con',
//       EndsWith: 'Termina con',
//       Contains: 'Contiene',
//       Equal: 'Igual',
//       NotEqual: 'No es igual',
//       IsNull: "Est nul",
//       NotNull: "Est non nulle",
//       IsEmpty: "Vide",
//       Like: "comme",
//       IsNotEmpty: "N'est pas vide",
//       NotEmpty: "Pas vide",
//       NotContains: "Ne contient pas",
//       NotEndsWith: "Ne se termine pas par",
//       NotStartsWith: "Ne commence pas par",
//       LessThan: 'Menos que',
//       LessThanOrEqual: 'Menor o igual',
//       ChooseDate: 'Elija una fecha',
//       EnterValue: 'Introduzca el valor',
//       Copy: 'Copiar',
//       Group: 'Agrupar por esta columna',
//       Ungroup: 'Desagrupar por esta columna',
//       autoFitAll: 'Ajustar automáticamente todas las columnas',
//       autoFit: 'Ajustar automáticamente esta columna',
//       Export: 'Exportar',
//       FirstPage: 'Primera página',
//       LastPage: 'Última página',
//       PreviousPage: 'Pagina anterior',
//       NextPage: 'Siguiente página',
//       SortAscending: 'Orden ascendente',
//       SortDescending: 'Orden descendiente',
//       EditRecord: 'Editar registro',
//       DeleteRecord: 'Eliminar el registro',
//       FilterMenu: 'Filtro',
//       SelectAll: 'Seleccionar todo',
//       Blanks: 'Espacios en blanco',
//       FilterTrue: 'Verdadero',
//       FilterFalse: 'Falso',
//       NoResult: 'No se encontraron coincidencias',
//       ClearFilter: 'Borrar filtro',
//       NumberFilter: 'Filtros numéricos',
//       TextFilter: 'Filtros de texto',
//       DateFilter: 'Filtros de fecha',
//       DateTimeFilter: 'Filtros de fecha',
//       MatchCase: 'Coincidencia',
//       Between: 'Entre',
//       CustomFilter: 'Filtro personalizado',
//       CustomFilterPlaceHolder: 'Introduzca el valor',
//       CustomFilterDatePlaceHolder: 'Elija una fecha',
//       AND: 'Y',
//       OR: 'O',
//       ShowRowsWhere: 'Mostrar filas donde:',
//       'Add': 'Ajouter',
//       'Edit': 'Modifier',
//       'Delete': 'Supprimer',
//       'Update': 'Mettre à jour',
//       'Cancel': 'Annuler',
//       'Excelexport': "Exporter vers Excel",
//       'filterMenuOperatorContains': "Contient",
//       'filterMenuOperatorStartsWith': "Commence par",
//       'filterMenuOperatorEndsWith': "Finit par",
//       'currentPageInfo': '{0} sur {1} pages',
//       'totalItemsInfo': '( {0} éléments )',
//       'pagerDropDown': 'Éléments par page',
//       'True': 'Vrai',
//       'False': 'Faux',
//       'GreaterThan': 'Supérieur à',
//       'GreaterThanOrEqual': 'Supérieur ou égal à',
//     },
//     pager: {
//       currentPageInfo: '{0} de {1} pages', 
//       totalItemsInfo: '({0} enregistrements)', 
//       firstPageTooltip: 'Aller à la première page',
//       lastPageTooltip: 'Aller à la dernière page', 
//       nextPageTooltip: 'Aller à la page suivante', 
//       previousPageTooltip: 'Revenir à la page précédente', 
//       nextPagerTooltip: 'Au groupe de pages suivant',
//       previousPagerTooltip: 'Au groupe de pages précédent',
//       pagerDropDown: 'Enregistrements par page',
//       pagerAllDropDown: 'Enregistrements',
//       All: 'Tout',
//     },
//   }
// });
L10n.load(fr);
loadCldr(gregorian, currencies, numbers, timeZoneNames);
export default i18n;

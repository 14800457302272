import { React, useEffect, useRef, useState } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DateTimePickerComponent, MaskedDateTime, Inject } from "@syncfusion/ej2-react-calendars";
import { useTranslation } from "react-i18next";
import { FormValidator } from '@syncfusion/ej2-inputs';
//import "./TripManagement.css";
import { enableRipple } from "@syncfusion/ej2-base";
import agent from "../../api/agent";
import { logOutUser } from '../TokenProvider/TokenProvider';
import { showToast } from '../Theme/Utils';
import TokenProvider from "../TokenProvider/TokenProvider";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

const BookAppointment = (props) => {
    const userDateRefIn = useRef(null);
    const userDateRefOut = useRef(null);
    const { token } = TokenProvider();
    const { t, i18n } = useTranslation();
    const [bookingDateTime, setBookingDateTime] = useState(props.inTime);
    const [confirmationNumber, setConfirmationNumber] = useState('');
    const [bookingDateTimeError, setBookingDateTimeError] = useState('');
    const [confirmationNumberError, setConfirmationNumberError] = useState('');


    enableRipple(true);

    const onSubmitEvent = async (e) => {
        let errorConf = '*Please enter confirmation number';
        let errorBookDate = '*Please enter booking date and time';

        if(!bookingDateTime && !confirmationNumber){
            setBookingDateTimeError(errorBookDate);
            setConfirmationNumberError(errorConf);
            return;
        }
        if (!bookingDateTime) {
            setBookingDateTimeError(errorBookDate);
            return;
        } else {
            setBookingDateTimeError('');
        }

        if (!confirmationNumber) {
            setConfirmationNumberError(errorConf);
            return;
        } else {
            setConfirmationNumberError('');
        }

        props.closeHandler();
    };


    return (
        <div className='container-flex'>
            <div className='control_wrapper' id='control_wrapper'>
                <div className='control_wrapper'>
                    <form id='formEvent' className="pd-1">
                        <div className='form-group'>
                            <span className="my-custom-text form-field-label">
                                {t('Booking Date & Time:')}
                            </span>
                            <DateTimePickerComponent
                                id='bookAppoinmentDT'
                                type='dateTime'
                                enableMask={true}
                                name='bookAppoinmentDT'
                                ref={userDateRefIn}
                                value={bookingDateTime}
                                change={(e) => {
                                    setBookingDateTime(e.value);
                                    setBookingDateTimeError(''); // Reset error on change
                                }}
                                placeholder={t('Booking Date & Time')}
                                floatLabelType='Never'
                                data-msg-containerid='errorForDateTimeIn'
                                className="form-control"
                                cssClass="e-medium"
                                serverTimezoneOffset={-4}
                            ></DateTimePickerComponent>
                            <Inject services={[MaskedDateTime]} />
                            <div className='e-error'>{bookingDateTimeError}</div>
                            {/* <div id='errorForDateTimeIn' /> */}
                        </div>
                        <div className='form-group my-custom-text mt-2' style={{ height: "55px" }}>
							<span className="form-field-label">
								{t('Confirmation Number')}
							</span>
							<TextBoxComponent
								type='text'
								id='comment'
								name='comment'
                                value={confirmationNumber}
                                onChange={(e) => {
                                    setConfirmationNumber(e.target.value);
                                    setConfirmationNumberError(''); // Reset error on change
                                }}
								placeholder={t('Confirmation Number')}
								floatLabelType='Never'
								data-msg-containerid='errorForComment'
								htmlAttributes={{ maxlength: "150" }}
							/>
							{/* <div id='errorForComment' /> */}
                            <div className='e-error'>{confirmationNumberError}</div>
						</div>
                    </form>
                    <div className='submitBtn mt-2 my-custom-modal-footer'>
                        <button
                            onClick={(e) => {
                                onSubmitEvent();
                                e.preventDefault();
                            }}
                            name='buttonSubmit'
                            className="my-custom-btn"
                            id='buttonSubmit'
                        >
                            {t("Submit")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookAppointment;

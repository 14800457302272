import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from 'react-hot-toast';
import { registerLicense } from "@syncfusion/ej2-base";
import "./i18n";

// registerLicense("Ngo9BigBOggjHTQxAR8/V1NHaF5cXmVCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdgWXZcdXVRR2VYVkd/WkU=");
registerLicense("Ngo9BigBOggjHTQxAR8/V1NAaF1cWGhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEFjW35acH1VTmFZUkd1Ww==");
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
	<BrowserRouter>
	<Toaster />
		<App />
	</BrowserRouter>
);
reportWebVitals();

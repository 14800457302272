import { useState, React, useEffect, useReducer, useRef } from "react";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent, UploaderComponent } from "@syncfusion/ej2-react-inputs";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { FormValidator } from "@syncfusion/ej2-inputs";
//import "./TripManagement.css";
import { enableRipple } from "@syncfusion/ej2-base";
import agent from "../../api/agent";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { DataUtil } from "@syncfusion/ej2-data";
import { useTranslation } from "react-i18next";
import {showToast} from '../Theme/Utils';
import TokenProvider,{ logOutUser } from "../TokenProvider/TokenProvider";

const Stop = (props) => {

	const userDateRef = useRef(null);
	const uploader = useRef(null);
	const [eventDateTime, setEventDateTime] = useState("");
	const [pdfFile, setPdf] = useState("");
	const [delayReason, setDelayReason] = useState();
	const { token } = TokenProvider();
	const { t, i18n } = useTranslation();
	let formObjectEvent = useRef();

	useEffect(() => {
		formObjectEvent.current = new FormValidator("#formEvent", optionsEvent);
	}, []);

	//let formObjectEvent;

	enableRipple(true);

	const initialState = { eventDateTime: "", comment: "" };
	const reducer = (state, action) => {
		switch (action.type) {
			case "update":
				return { ...state, [action.field]: action.value };

			default:
				return initialState;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const update = (field) => (event) => {
		//update action is dispatched to update the comments
		dispatch({ type: "update", field, value: event.value });
	};

	const onSubmitEvent = async (e) => {
		//sanity check
		if (eventDateTime === null) {
			return;
		}

		formObjectEvent.current.validate();
		if (formObjectEvent.current.validate()) {
			//	console.log(props, "SDS");
			agent
				.postEvent(
					props.token,
					props.tripID,
					new Date(eventDateTime).toLocaleString("en-US"),
					props.timeZone,
					props.sequenceID,
					state.delayReason === undefined ? "" : state.delayReason,
					state.comment,
					pdfFile,
					token.username,
					props.stopType === "ETA" ? 1 : props.stopType === "ARRIVED" ? 2 : props.stopType === "DEPARTED" ? 3 : 0,
					props.tenant,
					props.stopTypeEnum
				)
				.then((response) => {
					//console.log(response);
					if (response.status === 200) {
						setEventDateTime();
						setPdf();
						//alert("Added event status");
						setDelayReason();
						props.successful.current = true;
						props.closeDialogue(false);
						props.closeHandler();
						//	console.log(response.data);
					}
				})
				.catch((error) => {
					console.log(error);
					if (error.response === undefined) {
						showToast(t('Unable to update event status. Please check your internet connection'), 'error');
						return;
					}
					if (error.response?.status === 401) {
						showToast(t('Unable to add event status, please login again'), 'error');
          				logOutUser();
						return;
					}
					if (error?.response?.data.length > 0) {
						showToast(t('Unable to add event status. Error:') + " " + error.response.data, 'error');
					} else {
						showToast(t('Unable to add event status.'), 'error');
					}
				});

			formObjectEvent.current.reset();
		}
	};

	useEffect(() => {
		userDateRef.current.focusIn();
		agent
			.fetchDelayReason(props.token)
			.then((response) => {
				setDelayReason(DataUtil.parse.parseJson(response.data));
			})
			.catch((error) => {
				console.log("Error in calling API: ",error);
				if(error?.response?.status === 401) {
					showToast(t('Unable to add event status, please login again'), 'error');
					logOutUser();
				}
			});
	}, [props.token]);

	const optionsEvent = {
		// add the rules for validation
		rules: {
			dateTimePicker: {
				required: [true,("* "+ t("Please enter in a date and time"))],
			},
		},
	};

	const setSelected = (props) => {
		setPdf(props.filesData[0].rawFile);
	};

	useEffect(()=>{
		const uploadText = document.querySelector("#formEvent span.e-file-drop")
		if(uploadText){ uploadText.textContent = t("Or drop files here");}
	},[])

	return (
		<div className='container-flex'>
			<div className='control_wrapper' id='control_wrapper'>
				<div className='control_wrapper'>
					<form id='formEvent' className="pd-1">
						{props.stopType === "DEPARTED" && props.stopDescription === "Delivery" && (
							<div className='mb-2 my-custom-text information-text'>
								{t("Driver release from consignee (trip will be shown as delivered automatically on the last stop)")}
								<br />
							</div>
						)}
						{props.stopType === "ARRIVED" && props.stopDescription === "Delivery" && (
							<div className='my-custom-text mb-2 information-text'> {t("Time of arrival at consignee")}</div>
						)}
						{props.stopType === "ETA" && (
							<div className="my-custom-text mb-2 information-text"> {t("ETA will send notificaiton to customer and will not be reflected here")}</div>
						)}
						<div className='my-custom-text' style={{marginBottom:'1.5rem', maxHeight:'45px'}}>
							<span className="form-field-label required">
								{t('Date & Time')}
							</span>
							<DateTimePickerComponent
								id='dateTimePicker'
								name='dateTimePicker'
								type='dateTime'
								ref={userDateRef}
								value={eventDateTime}
								change={(e) => {
									setEventDateTime(e.value);
								}}
								placeholder={t('Date & Time')}
								floatLabelType='Never'
								data-msg-containerid='errorForDateTime'
								className="form-control"
								cssClass="e-medium"
							/>
							<div id='errorForDateTime' />
						</div>
						{delayReason && (
						<div className='form-group my-custom-text' style={{ height: "45px", marginTop: "1.5rem" }}>
								<span className="form-field-label">
									{t('Delay Reason')}
								</span>
								<DropDownListComponent
									dataSource={delayReason}
									id='delayReason'
									name='delayReason'
									fields={{ text: "description", value: "description" }}
									change={update("delayReason")}
									value={state.delayReason}
									data-msg-containerid='errroForDelayReason'
									placeholder={t('Delay Reason')}
								/>
							<div id='errroForDelayReason' />
						</div>
						)}
						<div className='form-group my-custom-text' style={{ height: "55px", marginTop: "1.5rem" }}>
							<span className="form-field-label">
									{t('Comment')}
							</span>
							<TextBoxComponent
								type='text'
								id='comment'
								name='comment'
								value={state.comment}
								change={update("comment")}
								placeholder={t('Comment')}
								floatLabelType='Never'
								data-msg-containerid='errorForComment'
								htmlAttributes={{ maxlength: "150" }}
							/>
							<div id='errorForDelayComment' />
						</div>
						<div className='form-group' >
						<div className='mb-2 mt-4 my-custom-text form-field-label'> {t("Proof Of Delivery PDF")}</div>
							<div>
								<span className='e-float-line mb-1'></span>
								<UploaderComponent
									type='file'
									ref={uploader}
									id='fileUpload'
									name='fileUpload'
									allowedExtensions='.pdf' //FIX
									multiple={false}
									minFileSize={20}
									selected={(e) => {
										setSelected(e);
									}}
									buttons={{ browse: t("Browse"), }}
								></UploaderComponent>
							</div>
							<div id='uploadError'></div>
						</div>
					</form>
					<div className='submitBtn mt-2 my-custom-modal-footer'>
						<button className="my-custom-btn" id='submitBtn' name='submitBtn' onClick={onSubmitEvent}>{t('Submit')}</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Stop;


export const DEFAULT_THEME = "light"
export const DEFAULT_LOCALE = "en"

export const getTheme = () =>{
    return localStorage?.getItem("current_theme") ? localStorage?.getItem("current_theme") : DEFAULT_THEME
}

export const setCurrentTheme = (theme) =>{
    if(theme && theme === 'dark'){
        document.body.classList.add('isDarkTheme')
    }
    else if(theme && theme === 'light'){
        document.body.classList.remove('isDarkTheme')
    }
    return theme ? localStorage.setItem("current_theme", theme) : localStorage.setItem("current_theme", DEFAULT_THEME);
}

export const toggleTheme = () =>{
    const theme = localStorage?.getItem("current_theme") ? localStorage?.getItem("current_theme") : DEFAULT_THEME;
    if(theme == "light"){
        localStorage.setItem("current_theme" , "dark")
    }
    else{
        localStorage.setItem("current_theme" , "light")
    }
}

export const getLocale = () => {
    return localStorage?.getItem("current_locale") ? localStorage?.getItem("current_locale") : DEFAULT_LOCALE
}

export const setCurrentLocale = (loc) =>{
    const token = JSON.parse(localStorage.getItem("token")) || {};
    if(loc){
        localStorage.setItem("current_locale", loc)
        token.locale = loc;
    }
    else{
        localStorage.setItem("current_locale", DEFAULT_LOCALE)
        token.locale = DEFAULT_LOCALE;
    }
    localStorage.setItem("token", JSON.stringify(token));
    return;
}
import { useEffect, useState, React, useRef, useCallback, useContext } from "react";
import {
	ColumnDirective,
	ColumnsDirective,
	GridComponent,
	Sort,
	Inject,
	Page,
	CommandColumn,
	DetailRow,
	Resize,
	Edit,
	Toolbar,
	ContextMenu,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import agent from "../../api/agent";
import Stop from "./Stop";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import InOut from "./InOut";
import AddDocument from "./AddDocument";
import ReactDOM from "react-dom/client";
import ThemeContext from '../Theme/ThemeContext';
import {VscNewFile} from "react-icons/vsc"
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { logOutUser } from '../TokenProvider/TokenProvider';
import {showToast} from '../Theme/Utils';
import { DateTime } from "luxon";
import ErrorBoundary from "../ErrorBoundary/ErrorBoundary";
import BookAppointment from "./BookAppointment";
//import { DialogComponent } from "@syncfusion/ej2-react-popups";
//import { borderLeftWidth } from "html2canvas/dist/types/css/property-descriptors/border-width";

const TripStop = (props) => {
	const { t, i18n } = useTranslation();
	const { theme, localeState } = useContext(ThemeContext);
	const [data, setData] = useState();
	const [stopType, setStopType] = useState();
	const grid = useRef();
	const gridItem = useRef();
	const changeSuccess = useRef();
	const [showInOut, setShowInOut] = useState(false);
	const [showDocument, setShowDocument] = useState(false);
	const [dialogAppointment, setDialogueAppointment] = useState(false);

	const dataShipmentStatusS2 = [
		{
			id: "A",
			description: t("Arrived"),
		},
		{
			id: "C",
			description: t("Departed"),
		},
	];

	const dataShipmentStatusS1 = [
		{
			id: "S",
			description: t("ETA "),
		},
		{
			id: "E",
			description: t("ETA Update"),
		},
		{
			id: "A",
			description: t("Arrived"),
		},
	];

	const dataShipmentStatusS3 = [
		{
			id: "C",
			description: t("Departed"),
		},
	];

	const contextMenuOpt = {
		updateInOut: t("Update In/Out"),
		addDocument: t("Add Document"),
		replceDocument: t("Replace Document")
	}

	const [dialogVisible, setDialogueVisible] = useState(false);

	useEffect(() => {
		//	alert(props.tripID);
		agent
			.fetchTripDetailsByTripID(props.token, props.tripID, props.tenant)
			.then((response) => {
				response.data = response.data.sort((a, b) => {
					return a.seq - b.seq;
				});

				response.data.forEach(function (item, index) {
					if (item?.scheDateBegin_Local) {
						item.scheDateBegin_Local = new Date(item.scheDateBegin_Local);
					}
					if (item?.acteDateEnd_Local) {
						item.acteDateEnd_Local = new Date(item.acteDateEnd_Local);
					}

					if (item?.acteDateBegin_Local) {
						item.acteDateBegin_Local = new Date(item.acteDateBegin_Local);
					}

					//console.log(item, index, item?.stopStatusDescription);
					if (item.stopStatusDescription === "Completed") {
						item.visible = false;
					} else {
						if (item.seq === 1) {
							//assigned or enroute
							item.visible = true;
						} else {
							if (response.data[index - 1]?.stopStatusDescription !== "Completed") {
								item.visible = false;
							} else {
								item.visible = true;
							}
							//if previous is completed => black
							//if previous is not completed => grey
						}
					}
					if(item.stopDescription){
						item.stopDescription_fr = t(item.stopDescription,{ lng: 'fr' })
					}
					item.TrackingStatus = item.stopStatus
					if(item.TrackingStatus && item.TrackingStatus === "E"){
						item.TrackingStatus = "S"
					}
				});

				if (JSON.stringify(data) !== JSON.stringify(response.data)) {
					setData(response.data);
				}
			})
			.catch((error) => {
				console.log("Error in calling API: ",error);
				if(error?.response?.status === 401) {
					showToast(t('Your session has expired. Please login again.'), 'error');
          			logOutUser();
				}
			});
	}, [props.tenant, props.token, props.tripID]);

	const closeHandler = async (e) => {
		//grid.current.showSpinner = true;

		await agent
			.fetchTripDetailsByTripID(props.token, props.tripID, props.tenant)
			.then((response) => {
				response.data.forEach(function (item, index) {
					if (item?.scheDateBegin_Local) {
						item.scheDateBegin_Local = new Date(item.scheDateBegin_Local);
					}
					if (item?.acteDateEnd_Local) {
						item.acteDateEnd_Local = new Date(item.acteDateEnd_Local);
					}
					if (item?.acteDateBegin_Local) {
						item.acteDateBegin_Local = new Date(item.acteDateBegin_Local);
					}

					//console.log(item, index, item?.stopStatusDescription);
					if (item.stopStatusDescription === "Completed") {
						item.visible = false;
					} else {
						if (item.seq === 1) {
							//assigned or enroute
							item.visible = true;
						} else {
							if (response.data[index - 1]?.stopStatusDescription !== "Completed") {
								item.visible = false;
							} else {
								item.visible = true;
							}
							//if previous is completed => black
							//if previous is not completed => grey
						}
					}

					if(item.stopDescription){
						item.stopDescription_fr = t(item.stopDescription,{ lng: 'fr' })
					}
					item.TrackingStatus = item.stopStatus
					if(item.TrackingStatus && item.TrackingStatus === "E"){
						item.TrackingStatus = "S"
					}
				});

				if (JSON.stringify(response.data) !== JSON.stringify(data)) {
					setData(response.data);
				}

				//console.log(response.data);
				//grid.current.refresh();

				//setSequenceID(0);
				//setStopType(response.data.stopStatus);
				//	console.log(response.data);
				//
				agent
					.fetchTrip(props.token, response?.data[0].tripNumber, response?.data[0].tenant)
					.then((result) => {
						//console.log(result.data);
						let newRow = result.data;

						if (newRow?.firstStopScheBegin) {
							newRow.firstStopScheBegin = new Date(newRow.firstStopScheBegin);
						}
						if (newRow?.lastStopSchEnd) {
							newRow.lastStopSchEnd = new Date(newRow.lastStopSchEnd);
						}
						//console.log(newRow, "new row");
						//console.log(result.data);
						props.dataGrid.current.setRowData(newRow.tripNumber, {
							tenant: newRow.tenant,
							numberOfStops: newRow.numberOfStops,
							tripMode: newRow.tripMode,
							equipment: newRow.equipment,
							lastDropCityState: newRow.lastDropCityState,
							firstPickCityState: newRow.firstPickCityState,
							statusDescription: newRow.statusDescription,
							carrierID: newRow.carrierID,
							firstPickupStopActualArrivalDateTime: newRow.firstPickupStopActualArrivalDateTime ?? "",
							firstPickupStopActualArrivalDateTime_local: newRow.firstPickupStopActualArrivalDateTime_local ?? "",
							lastStopTZName: newRow.lastStopTZName,
							tripID: newRow.tripID,
							reference: newRow.reference,
							tripNumber: newRow.tripNumber,
							codeTrip: newRow.codeTrip,
							status_Enum: newRow.status_Enum,
							enrouteDateTime: newRow.enrouteDateTime,
							enroute_Local: newRow.enroute_Local,
							deliveredDateTime: newRow.deliveredDateTime,
							delivery_Local: newRow.delivery_Local,
							del_TimeZone: newRow.del_TimeZone,
							firstStopName: newRow.firstStopName,
							firstStopAddress1: newRow.firstStopAddress1,
							firstStopAddress2: newRow.firstStopAddress2,
							firstStopCity: newRow.firstStopCity,
							firstStopState: newRow.firstStopState,
							firstStopPostal: newRow.firstStopPostal,
							firstStopScheBegin: newRow.firstStopScheBegin,
							firstStopSchBegin_Local: newRow.firstStopSchBegin_Local,
							firstStopSchEnd: newRow.firstStopSchEnd,
							firstStopSchEnd_Local: newRow.firstStopSchEnd_Local,
							lastStopName: newRow.lastStopName,
							lastStopAddress1: newRow.lastStopAddress1,
							lastStopAddress2: newRow.lastStopAddress2,
							lastStopCity: newRow.lastStopCity,
							lastStopState: newRow.lastStopState,
							lastStopPostal: newRow.lastStopPostal,
							lastStopScheBegin: newRow.lastStopScheBegin,
							lastStopSchBegin_Local: newRow.lastStopSchBegin_Local,
							lastStopSchEnd: newRow.lastStopSchEnd,
							lastStopSchEnd_Local: newRow.lastStopSchEnd_Local,
							first_TimeZone: newRow.first_TimeZone,
							last_TimeZone: newRow.last_TimeZone,
							miles: newRow.miles,
							totalWeight: newRow.totalWeight,
							totalPallets: newRow.totalPallets,
							totalCube: newRow.totalCube,
							totalCalDim1: newRow.totalCalDim1,
							//seq: newRow.seq,
						});

						//if(JSON.stringify(props.data) === JSON.stringify(props.data) )
						props.setData(props.data);
					})
					.catch((error) => {
						console.log("Error in calling API: ",error);
						if(error?.response?.status === 401) {
							showToast(t('Your session has expired. Please login again.'), 'error');
          					logOutUser();
						}
					})
					.finally(() => {
						//dataGrid.current.hideSpinner();
						//		setLoading(false);
						gridItem.current = null;
					});

				//props.data);setSequenceID
			})
			.catch((error) => {
				console.log("Error in calling API: ",error);
				if(error?.response?.status === 401) {
					showToast(t('Your session has expired. Please login again.'), 'error');
          			logOutUser();
				}
			})
			.finally(() => {
				//grid.current.showSpinner = false;
				//setSequenceID();
				//grid.current.refresh();
			});
	};

	const closeHandlerInOut = async (e) => {
		setShowInOut(false);
		//alert();
		grid.current.closeEdit();
		grid.current.endEdit();
		closeHandler();
	};

	const closeHandlerDocument = async (e) => {
		grid.current.closeEdit();
		setShowDocument(false);
		//alert();

		closeHandler();
	};

	const closeAppointmentModal = ()=>{
		setDialogueAppointment(false);
		closeHandler();
		grid.current.refresh();
	}

	const onChange = (args) => {
		var object = grid.current.getSelectedRecords();

		if (args.itemData.id === "U" && args.value === "U") {
			// setStopType("BOOK-APPOINTMENT");
			setDialogueAppointment(true);
		}
		else if (object.length > 0) {
			let k = { args };
			k.data = object[0];
			gridItem.current = k;
			setDialogueVisible(true);
		}
		if ((args.previousItemData.id === "E" || args.previousItemData.id === "S") && args.value === "E") {
			setStopType("ETA");
			setDialogueVisible(true);
		}

		if (args.previousItemData.id === "S" && args.value === "A") {
			setStopType("ARRIVED");
			setDialogueVisible(true);
		}

		if (args.previousItemData.id === "A" && args.value === "C") {
			setStopType("DEPARTED");
			setDialogueVisible(true);
		}
	};

	const itemTemplate = (data) => {
		return (
			<div className='container-flex' style={{ display: "flex" }}>
				<div className='pr-2'>{data.description}</div>
				{/* {data.description === "ETA" && (
					<ButtonComponent
						custom={data.description}
						onClick={buttonHandler.bind(this)}
						//cssClass='e-small'
						className='mr-2'
					>
						Update
					</ButtonComponent>
				)} */}
			</div>
		);
	};

	const dropdown = (args) => {
		const id = "dropdown"; //+ //args.data["description"];
		//console.log(args.data);
		const style = theme === 'dark' ? {color:'white', WebkitTextFillColor:"white" } :  {color:'black', WebkitTextFillColor:"black"};
		//s E A C
		if (args?.column?.headerText === "Shipment Status") {
			//1 is ETA //2 is Arrived //3 Departed
			var parentEle = ReactDOM.createRoot(args.cell);
			const TripStopStatus = args.data.TrackingStatus; //args.data.stopStatus;
			if (!grid.current) return;
			if (TripStopStatus === "S" || TripStopStatus === "E") {
				parentEle.render(
					<div>
						<DropDownListComponent
							id={id}
							value={TripStopStatus}
							dataSource={dataShipmentStatusS1}
							change={onChange.bind(this)}
							fields={{ text: "description", value: "id" }}
							placeholder='Update A Status'
							itemTemplate={itemTemplate.bind(this)}
							enabled={args.data.visible}
							style={style}
						/>
					</div>
				);
			}
			if (TripStopStatus === "A") {
				parentEle.render(
					<div>
						<DropDownListComponent
							id={id + 5}
							value={TripStopStatus}
							dataSource={dataShipmentStatusS2}
							change={onChange.bind(this)}
							fields={{ text: "description", value: "id" }}
							placeholder='Update A Status'
							itemTemplate={itemTemplate.bind(this)}
							enabled={args.data.visible}
							style={style}
						/>
					</div>
				);
			}
			if (TripStopStatus === "C") {
				parentEle.render(
					<div>
						<DropDownListComponent
							id={id + 7}
							value={TripStopStatus}
							dataSource={dataShipmentStatusS3}
							change={onChange.bind(this)}
							fields={{ text: "description", value: "id" }}
							placeholder='Update A Status'
							itemTemplate={itemTemplate.bind(this)}
							enabled={args.data.visible}
							style={style}
						/>
					</div>
				);
			}
		}
	};

	const addressFormatter = (field, data, column) => {
		return (
			getValue("stopAddress1", data) +
			(getValue("stopAddress2", data) === "" ? " " : ", ") +
			getValue("stopAddress2", data)
		);
	};

	const aptFormatter = (field, data, column) => {
		return getValue(field, data) === true ? "Yes" : "No";
	};

	const dateSpanFormatter = (field, data, column) => {
		return getValue("timeWindowBegin", data).substring(0, 5) + "-" + getValue("timeWindowEnd", data).substring(0, 5);
	};

	const dateStatusFormatter = (field, data, column) => {
		//console.log(data);

		if (
			getValue("stopStatusDescription", data) === "Completed" ||
			getValue("stopStatusDescription", data) === "Departed"
		) {
			return getValue("acteDateEnd_Local", data);
		}
		if (
			getValue("stopStatusDescription", data) === "Scheduled" ||
			getValue("stopStatusDescription", data) === "Enroute"
		) {
			return getValue("scheDateBegin_Local", data);
		}
		if (getValue("stopStatusDescription", data) === "Arrived") {
			return getValue("acteDateBegin_Local", data);
		}
	};

	function rowDataBound(args) {
		// add border to the row for nested table
		if (args?.row?.childNodes[0]?.style) {
			// args.row.childNodes[0].style = "border-left: 4px;border-left-style: solid;border-left-color:#20e29f";
			if (getValue("visible", args.data)) {
				// args.row.childNodes[0].style = "border-left: 6px;border-left-style: solid;border-left-color:#44444";
			}
		}
	}

	const templateDocument = useCallback(
		(args) => {
			// console.log(args, "Args");
			if (args.document) {
				return (
					<span
						onClick={(temp) => {
							agent.GetDocument(props.token, props.tripID, args.seq).then((response) => {
								//console.log(response);

								const linkSource = `data:application/pdf;base64,${response.data.Content}`;
								const downloadLink = document.createElement("a");
								const fileName = "POD " + props.tripID + " Stop " + args.seq + ".pdf"; // `TipManagement-${e.rowData.tripNumber}.pdf`;
								downloadLink.href = linkSource;
								downloadLink.download = fileName;
								downloadLink.click();
							})
							.catch((error) => {
								console.log("Error in calling API: ",error);
								if(error?.response?.status === 401) {
									showToast(t('Your session has expired. Please login again.'), 'error');
									logOutUser();
								}
							})
						}}
						className='e-icons e-medium e-copy'
					></span>
				);
			} else {
				if(args.stopStatus !== "S"){
					return (<div>
						<VscNewFile style={{ color: (theme === 'dark' ? "#adb5bd" : "#6c757d") ,transform:"scale(130%)"}} />
					</div>);
				}
				else{
					return (<div>
					</div>);
				}
				
			}
		},
		[props.token, props.tripID]
	);

	function docHeader(args) {
		return (<div><VscNewFile style={{transform:"scale(110%)"}} /></div>);
	}

	function contextMenuClick(args) {
		if (grid.current && args.item.id === "inOut") {
			if (!showInOut) {
				setShowInOut(true);
			}
		}
		if ((grid.current && args.item.id === "addDocument") || (grid.current && args.item.id === "replaceDocument")) {
			if (!showDocument) {
				setShowDocument(true);
			}
		}
	}

	const onRecordClick = (args) => {
		// console.log(args, "args");
		if (!grid.current.contextMenuModule) {
			return;
		}
		var contextMenuObj = grid.current.contextMenuModule.contextMenu;

		if (args.rowIndex !== undefined || args.rowIndex !== -1) {
			gridItem.current = args;
		}

		if (args.data.stopStatusDescription === "Completed") {
			contextMenuObj.enableItems([contextMenuOpt.updateInOut], true);

			if (args.data?.document) {
				//console.log("with document");
				contextMenuObj.enableItems([contextMenuOpt.replceDocument], true);
				contextMenuObj.enableItems([contextMenuOpt.addDocument], false);
			} else {
				contextMenuObj.enableItems([contextMenuOpt.addDocument], true);
				contextMenuObj.enableItems([contextMenuOpt.replceDocument], false);
				//console.log("without documetns");
			}
		} else {
			contextMenuObj.enableItems([contextMenuOpt.updateInOut], false);
			contextMenuObj.enableItems([contextMenuOpt.replceDocument], false);
			contextMenuObj.enableItems([contextMenuOpt.addDocument], false);
		}
	};

	const actionBegin = (args) => {
		//console.log(args, " action begin");
		if (args.requestType === "beginEdit") {
			//console.log(args, args.rowData, "args22");
			if (args.rowData?.stopStatusDescription !== "Completed") {
				args.cancel = true;
				/** cast string to integer value */
				//   setValue('data.Freight',
				// 	parseFloat((args.form.querySelector("#Freight") as HTMLInputElement).value), args);
			}
		}
	};

	const GridDialog = (propsDialog) => {
		//alert();
		//const a = [props, grid.current];

		// console.log("propsDialog ", propsDialog);
		const [selectedStopGridDialogue, setSelectedStopGridDialogue] = useState(0);
		// var s = grid.current.element.ej2_instances[0].element.ej2_instances[0].editModule.dialogObj;

		useEffect(()=>{
			// Get the reference to the div element
			const divElement = document.getElementById("gridTripStop_dialogEdit_wrapper_title");
			if(divElement){
				if(selectedStopGridDialogue === 0){
					divElement.innerText = t("Select Option");
				}
				else if(selectedStopGridDialogue === 1){
					divElement.innerText = t("Update In/Out");
				}
				else if(selectedStopGridDialogue === 2){
					divElement.innerText = t("Add Document");
				}
			}
		},[selectedStopGridDialogue])

		// if (s) {
		// 	//console.log(s, "sssss");
		// 	//s.header = "test";
		// 	//s.querySelector(".e-title-text").innerText = "sssss";
		// 	//console.log(s, "Ss");
		// 	//").querySelectorAll("[name]");
		// 	///s.querySelector(".e-title-text").innerText = "Trip: " + props.tripID + " Stop: " + propsDialog.seq;
		// }
		return (
			<div>
				{selectedStopGridDialogue === 0 && (
					<div className='container' style={{ display: "flex", justifyContent:'center', flexDirection: "column", padding: "1rem" }}>
						<button
							className="btn btn-outline-secondary active"
							onClick={(e) => {
								setSelectedStopGridDialogue(1);
								e.preventDefault();
							}}
							style={{ margin: "1rem" }}
						>
							{t("Update In/Out")}
						</button>
						<button
							className="btn btn-outline-secondary active"
							onClick={(e) => {
								setSelectedStopGridDialogue(2);
								e.preventDefault();
							}}
							style={{ margin: "1rem" }}
						>
							{t("Add or Replace Document")}
						</button>
					</div>
				)}
				{selectedStopGridDialogue === 1 && (
					<InOut
						token={props.token}
						user={props.user}
						tripID={props.tripID}
						sequenceID={propsDialog.seq}
						setShowInOut={setShowInOut}
						closeHandler={closeHandlerInOut}
						successful={changeSuccess}
						tenant={props.tenant}
						inTime={propsDialog.acteDateBegin_Local} ////////
						outTime={propsDialog.acteDateEnd_Local} /////////
						//	setInTime={setInTime}
						//	setOutTime={setOutTime}
					></InOut>
				)}

				{selectedStopGridDialogue === 2 && (
					<AddDocument
						token={props.token}
						user={props.user}
						tripID={props.tripID}
						sequenceID={propsDialog.seq}
						setDialogueVisible={setShowDocument}
						successful={changeSuccess}
						tenant={props.tenant}
						//stopTypeEnum={stopTypeEnum}
						//stopType={gridItem.current.data.stopType}
						//stopDescription={stopDescription}
						closeHandler={closeHandlerDocument}
						// inTime={inTime}
						// outTime={outTime}
						// setInTime={setInTime}
						// setOutTime={setOutTime}
					></AddDocument>
				)}
			</div>
		);
	};

	const actionComplete = (args) => {
		if ((args.requestType === 'beginEdit')) {
			const dialogHeader = args.dialog.element.querySelector('.e-dlg-header-content');
			if(dialogHeader){
				args.dialog.element.classList.add('my-custom-edit-dialogue');
				if(theme === "dark"){
					args.dialog.element.classList.add('isDarkTheme');
				}
				else{
					args.dialog.element.classList.remove('isDarkTheme');
				}
			}
        }
	};

	function closeHandlerRegular(e) {
		setDialogueVisible(false);
		closeHandler();
		grid.current.refresh();
		//alert();
	}

	return (
		<div className=''>
			<ErrorBoundary compName="Modal in InOut">
			{gridItem.current?.data?.seq && (
				<Modal
					size='sm'
					show={showInOut}
					//onHide={() => setShowInOut(false)}
					onHide={() => {
						//closeHandlerInOut();
						closeHandlerInOut();
					}}
					className={(theme === "dark"? "isDarkTheme" : " ")}
				>
					<Modal.Header className="my-custom-modal-header" closeButton>
						{t("Trip")}: {props.tripID} {t("Seqeunce")}: {gridItem.current?.data?.seq}
					</Modal.Header>
					<Modal.Body className="pd-0">
						<InOut
							token={props.token}
							user={props.user}
							tripID={props.tripID}
							sequenceID={gridItem.current?.data?.seq}
							setShowInOut={setShowInOut}
							closeHandler={closeHandler}
							successful={changeSuccess}
							tenant={props.tenant}
							inTime={gridItem.current?.data?.acteDateBegin_Local}
							outTime={gridItem.current?.data?.acteDateEnd_Local}
						></InOut>
					</Modal.Body>
				</Modal>
			)}
			</ErrorBoundary>

			<ErrorBoundary compName="Modal in AddDocument">
			{gridItem.current?.data?.seq && (
				<Modal
					size='sm'
					show={showDocument}
					onHide={() => {
						closeHandlerDocument();
					}}
					className={(theme === "dark"? "isDarkTheme" : " ")}
				>
					<Modal.Header className="my-custom-modal-header" closeButton>
						{t("Trip")}: {props.tripID} {t("Seqeunce")}: {gridItem.current?.data?.seq}
					</Modal.Header>
					<Modal.Body className="pd-0">
						<AddDocument
							token={props.token}
							user={props.user}
							tripID={props.tripID}
							sequenceID={gridItem.current?.data?.seq}
							setDialogueVisible={setShowDocument}
							successful={changeSuccess}
							tenant={props.tenant}
							closeHandler={closeHandlerDocument}
						></AddDocument>
					</Modal.Body>
				</Modal>
			)}
			</ErrorBoundary>

			<ErrorBoundary compName="Modal in Stop">
			<Modal size='sm' show={dialogVisible} className={(theme === "dark"? "isDarkTheme" : " ")} onHide={closeHandlerRegular}>
				<Modal.Header className="my-custom-modal-header" closeButton>
					{t("Trip")}: {props.tripID} {t("Seqeunce")}: {gridItem.current?.data?.seq}
				</Modal.Header>
				<Modal.Body className="pd-0">		
					<Stop
						token={props.token}
						user={props.username}
						timeZone = {gridItem.current?.data?.timeZone}
						tripID={props.tripID}
						sequenceID={gridItem.current?.data?.seq}
						stopType={stopType}
						closeDialogue={setDialogueVisible}
						successful={changeSuccess}
						tenant={gridItem.current?.data?.tenant}
						closeHandler={closeHandlerRegular}
						stopTypeEnum={gridItem.current?.data?.stopType}
						stopDescription={gridItem.current?.data?.stopDescription}
					></Stop>
				</Modal.Body>
			</Modal>
			</ErrorBoundary>

			<ErrorBoundary compName="Modal in BookAppointment">
			<Modal size='sm' show={dialogAppointment} className={(theme === "dark"? "isDarkTheme" : " ")} onHide={closeAppointmentModal}>
				<Modal.Header className="my-custom-modal-header" closeButton>
					{t("Trip")}: {props.tripID} {t("Seqeunce")}: {gridItem.current?.data?.seq}
				</Modal.Header>
				<Modal.Body className="pd-0">
					<BookAppointment
						token={props.token}
						user={props.username}
						tripID={props.tripID}
						sequenceID={gridItem.current?.data?.seq}
						stopType={stopType}
						closeDialogue={setDialogueVisible}
						successful={changeSuccess}
						tenant={gridItem.current?.data?.tenant}
						closeHandler={closeAppointmentModal}
						stopTypeEnum={gridItem.current?.data?.stopType}
						stopDescription={gridItem.current?.data?.stopDescription}
					></BookAppointment>
				</Modal.Body>
			</Modal>
			</ErrorBoundary>

			<ErrorBoundary compName="Modal in GridComponent">
			{data && (
				<GridComponent
					delayUpdate={true}
					actionBegin={actionBegin.bind(this)}
					actionComplete={actionComplete.bind(this)}
					dataSource={data}
					id='gridTripStop'
					actionFailure={(x) => console.log(" error in the $: ",x)}
					rowDataBound={rowDataBound.bind(this)}
					className='mr-2'
					// locale={localeState === "fr" ? 'fr-FR' : 'en-US'}
					// height={"200"}
					width={"auto"}
					allowSorting={false}
					sortSettings={{
						columns: [{ field: "seq", direction: "Ascending" }],
					}}
					allowTextWrap={true}
					textWrapSettings={{ wrapMode: "Both" }}
					allowPaging={true}
					pageSettings={{ pageSize: 4 }}
					editSettings={{ allowEditing: true, mode: "Dialog", template: GridDialog }} // mode: "Normal"
					allowResizing={true}
					ref={grid}
					queryCellInfo={dropdown.bind(this)}
					rowSelected={onRecordClick.bind(this)}
					contextMenuItems={[
						{ text: contextMenuOpt.updateInOut, target: ".e-content", id: "inOut" },
						// { text: "Update In", target: ".e-content", id: "in" },
						{ text: contextMenuOpt.addDocument, target: ".e-content", id: "addDocument" },
						{ text: contextMenuOpt.replceDocument, target: ".e-content", id: "replaceDocument" },
					]}
					contextMenuClick={contextMenuClick.bind(this)}
				>
					<ColumnsDirective>
						<ColumnDirective
							headerText="Stop #"
							headerTemplate={()=>(
								<>{t("Stop #")}</>
							)}
							field='seq'
							textAlign='Left'
							isPrimaryKey={true}
							maxWidth={90}
							width={90}
							minWidth={90}
							disableHtmlEncode={false}
							type="number"
						/>
						<ColumnDirective
							headerTemplate={()=>(
								<div><VscNewFile style={{transform:"scale(110%)"}} /></div>
							)}
							headerText={"Doc"}
							field='document'
							textAlign='Center'
							//isPrimaryKey={true}
							maxWidth={45}
							width={45}
							minWidth={45}
							disableHtmlEncode={false}
							template={templateDocument}
							type="string"
						/>
						<ColumnDirective
							headerText="Type"
							headerTemplate={()=>(
								<>{t("Type")}</>
							)}
							// field='stopDescription'
							field={localeState === "fr" ? "stopDescription_fr" : "stopDescription"}
							clipMode='Ellipsis'
							maxWidth={90}
							minWidth={90}
							width={90}
							type="string"
						/>
						<ColumnDirective
							headerText="Shipment Status" //do not remove space
							textAlign='Left'
							headerTemplate={()=>(
								<>{t("Shipment Status")}</>
							)}
							minWidth={100}
							width={180}
							maxWidth={180}
							type="string"
						/>
						<ColumnDirective 
							headerText="Name"
							headerTemplate={()=>(
								<>{t("Name")}</>
							)} 
							field='stopName' 
							width={120} 
							maxWidth={120} 
							type="string"
						/>
						<ColumnDirective
							headerText="Address"
							headerTemplate={()=>(
								<>{t("Address")}</>
							)} 
							field='address'
							maxWidth={200}
							minWidth={120}
							width={130}
							valueAccessor={addressFormatter}
							type="string"
						/>
						<ColumnDirective 
							headerText='City' field='stopCity' width={120} minWidth={120} maxWidth={120} 
							headerTemplate={()=>(
								<>{t("City")}</>
							)}
							type="string"
						/>
						<ColumnDirective
							headerText="State"
							headerTemplate={()=>(
								<>{t("State")}</>
							)}
							field='stopState'
							width={110}
							minWidth={110}
							maxWidth={110}
							disableHtmlEncode={false}
							type="string"
						/>
						<ColumnDirective
							headerText="ZIP Code"
							headerTemplate={()=>(
								<>{t("ZIP Code")}</>
							)}
							field='stopPostal'
							maxWidth={100}
							minWidth={100}
							width={100}
							disableHtmlEncode={false}
							type="string"
						/>
						<ColumnDirective
							headerText="Instructions"
							headerTemplate={()=>(
								<>{t("Instructions")}</>
							)}
							field='instructions'
							maxWidth={520}
							// minWidth={150}
							width={200}
							type="string"
						/>
						<ColumnDirective
							headerText="Sch. Date"
							headerTemplate={()=>(
								<>{t("Sch. Date")}</>
							)}
							field='schDate'
							type='dateTime'
							format='MM/dd/yyyy'
							textAlign='Left'
							maxWidth={120}
							minWidth={115}
							width={100}
							valueAccessor={dateStatusFormatter}
							allowSorting={false}
						/>
						<ColumnDirective
							headerText={t("Open and<br>Close Time")}
							// headerTemplate={()=>(
							// 	<>{t("Open and Close Time")}</>
							// )}
							headerTextAlign="Center"
							disableHtmlEncode={false}
							field='dateSpan'
							// type="time"
							textAlign='Left'
							minWidth={115}
							width={115}
							maxWidth={115}
							valueAccessor={dateSpanFormatter}
						/>
						{/*<ColumnDirective
							headerText="Appt Req"
							headerTemplate={()=>(
								<>{t("Appt Req")}</>
							)}
							field='appointment'
							textAlign='Left'
							maxWidth={100}
							valueAccessor={aptFormatter}
						/>*/}

						{/* <ColumnDirective headerText='Message CMIS' field='' width='0' textAlign='Left' maxWidth={80} />
						<ColumnDirective
							headerText='Message CMIS'
							field='tenant'
							width='100'
							textAlign='Left'
							maxWidth={0}
							hideAtMedia={true}
						/> */}
					</ColumnsDirective>
					<Inject services={[Sort, Page, CommandColumn, DetailRow, Resize, Edit, Toolbar, ContextMenu]} />
				</GridComponent>
			)}
			</ErrorBoundary>
		</div>
	);
};

export default TripStop;

// import { ScheduleComponent, Day, Week, WorkWeek, Month, Agenda, Inject } from '@syncfusion/ej2-react-schedule';
import React, { useEffect, useState, useRef, useContext, useCallback } from 'react';
import { FormValidator } from '@syncfusion/ej2-inputs';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Button } from "react-bootstrap";
import { DatePickerComponent, Inject, MaskedDateTime } from '@syncfusion/ej2-react-calendars';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ColumnDirective, ColumnsDirective, CommandColumn, GridComponent, Page, Sort } from '@syncfusion/ej2-react-grids';

import "./CapacityBoard.css";
import { TextBoxComponent, NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import agent from '../../api/agent';

import moment from 'moment';
import { DataUtil } from '@syncfusion/ej2-data';
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import { Card, Container, Row, Col } from 'react-bootstrap';
import {showToast} from '../Theme/Utils';
//import { Navbar } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ThemeContext from '../Theme/ThemeContext';
import { MdOutlineFileCopy } from 'react-icons/md';
import { RiDeleteBin2Line } from 'react-icons/ri';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';

// TODO: expire token at b/e
const CapacityBoard = () => {
  const { t, i18n } = useTranslation();
  const source = 'CapacityBoard';
  const [userDefaults, setUserDefaults] = useState('');
  const dAreaRef = useRef(null);
  const oAreaRef = useRef(null);
  const { theme, setTheme } = useContext(ThemeContext);
  const dataGrid = useRef();

  const { token, setToken } = TokenProvider();
  const cbToken = token.token;
  const username = token.username;

  const [equipmentList, setEquipmentList] = useState(['']);
  const [stateList, setStateList] = useState([]);
  const [quantity, setQuantity] = useState('');
  const [date, setDate] = useState('');
  const [equipmentHistory, setEquipmentHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [truckType, setTruckType] = useState();

  // fix states going in
  const [oState, setoState] = useState('');
  const [oArea, setoArea] = useState('');
  const [dState, setdState] = useState('');
  const [dArea, setdArea] = useState('');
  const [comments, setComments] = useState('');
  const [placeKey, setPlaceKey] = useState(0);

  function helperJsonToArray(json_data) {
    const result = [];
    for (const i in json_data) result.push(i);
    return result;
  }

  function findState(value) {
    for (const key in stateList) {
      if (stateList[key] === value) {
        return key;
      }
    }
  }

  useEffect(()=>{
    if(i18n && i18n.language && placeKey > -1){
      setTimeout(()=>{
        setPlaceKey(prev=>prev+1);
      },100)
    }
},[i18n.language])

  // function logout() {
  //   setToken('');
  //   window.location.reload();
  // }

  const dateChangeHandler = (event) => {
    setDate(moment(event.value, 'YYYY-MM-DDTHH:mm:ss.SSSS').utcOffset(0, true).format().toString().replace('Z', ''));
  };

  const quantityChangeHandler = (event) => {
    setQuantity(event.value);
  };

  let formObject;

  useEffect(() => {
    agent
      .fetchEquipmentList()
      .then((response) => {
        setEquipmentList(response.data);
      })
      .catch((error) => {
        console.log("Error in calling API: ",error);
        if(error?.response?.status === 401) {
          showToast(t('Your session has expired. Please login again.'), 'error');
          logOutUser();
        }
      });

    agent
      .fetchStateList()
      .then((response) => {
        // setStateList();
        const tempListUSA = { 'ALL USA': 'ALL USA' };
        const tempListCA = { 'ALL CANADA': 'ALL CANADA' };
        const tempList = Object.assign(tempListCA, tempListUSA);
        const responseList = response.data;
        Object.assign(tempList, responseList);
        setStateList(tempList);
      })
      .catch((error) => {
        console.log("Error in calling API: ",error);
        if(error?.response?.status === 401) {
          showToast(t('Your session has expired. Please login again.'), 'error');
          logOutUser();
        }
      });

    agent
      .fetchDefaults(cbToken, source)
      .then((response) => {
        // if(typeof(response.data) === "")
        setUserDefaults(DataUtil.parse.parseJson(response.data));
        const temp = DataUtil.parse.parseJson(response.data);
        // eslint-disable-next-line array-callback-return
        temp.map((item) => {
          if (item.identifier.trim() === 'origin') {
            setoArea(item.value); //setdArea(item.value); //[privious]
          }
        });
      })
      .catch((error) => {
        if (error.response === undefined) { 
          // alert('Please check your internet connection');
          // alert("You don't have access to it. please contact site adminstrator.");
        }
        console.log("Error in calling API: ",error);
				if (error?.response?.status === 401) {
					showToast(t('Your session has expired. Please login again.'), 'error');
          logOutUser();
				}
      });

    setLoading(!loading);
  }, []);

  const options = {
    // validation rules
    rules: {
      date: {
        required: [true, "* "+t('Please enter in a date')],
        date: [true],
      },
      dropdown: {
        required: [true, "* "+t('Please select a truck')],
        regex: ['^[A-z]+'],
      },
      quantity: {
        required: [true, "* "+t('Please enter a qty')],
        range: [1, 100],
      },
    },
  };

  formObject = new FormValidator('#form1', options);

  const onSubmit = (e) => {
    if (formObject.validate()) {
      // sanity check for inputs
      if (equipmentList[truckType] === 0 || quantity === 0 || date.length === 0) {
        showToast(t('Please select the equipment type again'), 'error');
        return;
      }

      agent
        .postCapacity(
          cbToken,
          equipmentList[truckType],
          date,
          quantity,
          oState === undefined ? '' : stateList[oState],
          oArea,
          dState === undefined ? '' : stateList[dState],
          dArea,
          comments,
          username
        )
        .then((result) => {
          if (result.status === 401) {
            logOutUser();
          }

          if (result.status === 200) {
            formObject.reset();
            setDate('');
            setQuantity('');
            setoState('');
            setoArea('');
            setdArea('');
            setdState('');
            setComments('');
            setTruckType(null);
            setLoading(!loading);

            if (userDefaults.length > 0) {
              userDefaults.map((item) => {
                // console.log(item.identifier);
                if (item.identifier.trim() === 'origin') {
                  setoArea(item.value); //setdArea(item.value); [privious]
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log("Error in calling API: ",error);
          showToast(t('Unable to post capacity. Please refresh the page and check your internet connection.'), 'error');
          if(error?.response?.status === 401) {
            showToast(t('Your session has expired. Please login again.'), 'error');
            logOutUser();
          }
        });
      resetHandle();
    }
  };

  useEffect(() => {
    agent
      .fetchEquipmentHistory(cbToken)
      .then((response) => {
        if (response.hasOwnProperty('status')) {
          if (response.status === 401 || response.status === 400) {
            logOutUser();
          }
        }

        const result = DataUtil.parse.parseJson(response.data).reverse();

        for (let i = 0; i < result.length; ++i) {
          result[i].destinationState = findState(result[i].destinationState);
          result[i].originState = findState(result[i].originState);
        }
        setEquipmentHistory(result);
      })
      .catch((error) => {
        console.log("Error in calling API: ",error);
				if (error?.response?.status === 401) {
					showToast(t('Your session has expired. Please login again.'), 'error');
          logOutUser();
				}
      });
  }, [loading, stateList]);

  const resetHandle = (_e) => {
    // console.log(userDefaults)
    formObject.reset();
    if (userDefaults.length > 0) {
      userDefaults.map((item) => {
        if (item.identifier.trim() === 'origin') {
          oAreaRef.current.value = item.value; //dAreaRef.current.value = item.value; [privious]
          setoArea(item.value); //setdArea(item.value); [privious]
        }
      });
    }
  };

  const pageSettings = { pageSize: 30 };
  const wrapSettings = { wrapMode: 'Header' };

  const commandSettings = [
    {
      type: 'Delete',
      buttonOption: { content: 'Delete', cssClass: 'e-outline' },
    },
  ];

  const commandSettingsCopy = [{ type: 'Edit', buttonOption: { content: 'Copy', cssClass: 'e-outline' } }];

  const equipmentDropDown = useRef(null);
  const dSateRef = useRef(null);
  const oStateRef = useRef(null);
  const quantityRef = useRef(null);
  const equipmentHistoryRef = useRef(equipmentHistory);

  useEffect(() => {
    equipmentHistoryRef.current = equipmentHistory;
  }, [equipmentHistory]);

  const commandClickHandler = useCallback((e) => {
    const updatedEquipmentHistory = [...equipmentHistoryRef.current];
    if (e.commandColumn.type === 'Edit') {
      setQuantity(e.rowData.quantityAvailable);
      quantityRef.current.value = e.rowData.quantityAvailable;

      equipmentDropDown.current.value = e.rowData.equipmentName;
      setTruckType(e.rowData.equipmentName);

      setdState(e.rowData.destinationState === undefined ? null : e.rowData.destinationState);
      dSateRef.current.value = e.rowData.destinationState === undefined ? null : e.rowData.destinationState;

      setdArea(e.rowData.destinationCityArea === undefined ? '' : e.rowData.destinationCityArea);
      dAreaRef.current.value = e.rowData.destinationCityArea === undefined ? '' : e.rowData.destinationCityArea;

      setoArea(e.rowData.originCityArea === undefined ? '' : e.rowData.originCityArea);

      setoState(e.rowData.originState === undefined ? null : e.rowData.originState);
      oStateRef.current.value = e.rowData.originState === undefined ? null : e.rowData.originState;

      setComments(e.rowData.comments === undefined ? '' : e.rowData.comments);
    } else if (e.commandColumn.type === 'Delete') {
      agent
        .deleteCapacity(cbToken, e.rowData.capacityID, username)
        .then((response) => {
          if (response.status === 401) {
            logOutUser();
          }
          setEquipmentHistory(updatedEquipmentHistory.filter((item) => item.capacityID !== e.rowData.capacityID));
        })
        .catch((error) => {
          showToast(t('Unable to delete. Please check your internet connection. If the error continues, please contact the Help Desk'), 'error');
          console.log("Error in calling API: ",error);
          if(error?.response?.status === 401) {
            showToast(t('Your session has expired. Please login again.'), 'error');
            logOutUser();
          }
			});
    }
  }, []);

  // const sortSettings = { field: 'enteredDateTime', direction: 'Ascending' };

  const style = {
    fontSize: '10px',
    fontFamily: 'Nunito Sans',
    color: theme === 'light' ? 'black' : 'white',
  };


  function focus(args) {
    if (!args.target.parentElement.classList.contains('e-input-in-wrap')) {
      args.target.parentElement.classList.remove('e-input-focus');
    }
    else {
      args.target.parentElement.parentElement.classList.remove('e-input-focus');
    }
  }

  function gridLoaded(args) {
    dataGrid.current.refreshHeader();
    // dataGrid.current.autoFitColumns();
  }

  return (
    <div className="container-flex" style={{ padding: "1.5rem", paddingBottom: "4rem" }}>
      <div className=''>
        <h1 className="mt-4 my-custom-text main-header-text">
          {t('Capacity Board')}
        </h1>

        <div style={{ width: 'max-content' }}>
          <div className='capacity-board-form mt-4'>
            <div className="my-custom-text sub-header-text">
              {t('New Capacity Location')}
            </div>
            <form
              // className="ml-3 container-fluid"
              id="form1"
            // onReset={resetHandle}
            // onSubmit={(e) => {
            //   e.preventDefault();
            //   onSubmit(e);
            // }}
            // method='post'
            >
              <Container style={{marginTop:'1.5rem'}}>
                {/* the new grid  */}
                <Row>
                  <Col xs={12} lg={4} className='pd-0'>
                    <div className="capacity-form-fields  my-custom-text">
                      <span className="form-field-label required">
                        {t('Equipment Type')}
                      </span>
                      <div className="" >
                        <DropDownListComponent
                          required={true}
                          onChange={(e) => {
                            setTruckType(e.target.value);
                          }}
                          ref={equipmentDropDown}
                          value={truckType}
                          placeholder={t('Truck Type')}
                          floatLabelType="Never"
                          //className="form-control-sm"
                          cssClass="e-medium"
                          className='e-field'
                          dataSource={helperJsonToArray(equipmentList)}
                          id="dropdown"
                          data-msg-containerid="errorForDropDown"
                        ></DropDownListComponent>
                        <div id="errorForDropDown"> </div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='pd-0'>
                  <div className="capacity-form-fields my-custom-text">
                      <span className='form-field-label'>{t('From')}</span>
                      <div className="">
                        {/* <DropDownListComponent //[privious]
                          onChange={(e) => setdState(e.target.value)}
                          placeholder={t('State/Province')}
                          dataSource={helperJsonToArray(stateList)}
                          value={dState}
                          ref={dSateRef}
                        ></DropDownListComponent> */}
                        <DropDownListComponent
                          className="form-control form-control-sm"
                          placeholder={t('State/Province')}
                          dataSource={helperJsonToArray(stateList)}
                          onChange={(e) => {
                            setoState(e.target.value);
                          }}
                          value={oState}
                          ref={oStateRef}
                        ></DropDownListComponent>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='pd-0'>
                  <div className="capacity-form-fields my-custom-text">
                      <span className='form-field-label'>
                        {t('Pickup City/Area')}
                      </span>
                      <div className="">
                        {/* <TextBoxComponent //[privious]
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('Pickup City/Area')}
                          onChange={(e) => setdArea(e.target.value)}
                          key={"pickupcityarea"+placeKey}
                          value={dArea}
                          ref={dAreaRef}
                          htmlAttributes={{ maxlength: '100' }}
                        /> */}
                        <TextBoxComponent
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('Pickup City/Area')}
                          key={"DestinationCityArea"+placeKey}
                          onChange={(e) => setoArea(e.target.value)}
                          value={oArea}
                          ref={oAreaRef}
                          htmlAttributes={{ maxlength: '100' }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={4} className='pd-0'>
                  <div className="capacity-form-fields my-custom-text ">
                      <span className="form-field-label required">
                        {t('Qty')}
                      </span>
                      <div className="">
                        <NumericTextBoxComponent
                          data-msg-containerid="errorQty"
                          // required ={true}
                          onFocus={focus}
                          format="N0"
                          validateDecimalOnType={true}
                          decimals={0}
                          min={0}
                          cssClass="e-medium"
                          onChange={quantityChangeHandler}
                          value={quantity}
                          id="quantity"
                          name="quantity"
                          placeholder={t('Quantity')}
                          key={"quantity"+placeKey}
                          ref={quantityRef}
                        />
                        <div id="errorQty"></div>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='pd-0'>
                  <div className="capacity-form-fields my-custom-text">
                      <span className='form-field-label'>{t('To')}</span>
                      <div className="">
                        {/* <DropDownListComponent //[privious]
                          className="form-control form-control-sm"
                          placeholder={t('State/Province')}
                          dataSource={helperJsonToArray(stateList)}
                          onChange={(e) => {
                            setoState(e.target.value);
                          }}
                          value={oState}
                          ref={oStateRef}
                        ></DropDownListComponent> */}
                        <DropDownListComponent
                          onChange={(e) => setdState(e.target.value)}
                          placeholder={t('State/Province')}
                          dataSource={helperJsonToArray(stateList)}
                          value={dState}
                          ref={dSateRef}
                        ></DropDownListComponent>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={4} className='pd-0'>
                  <div className="capacity-form-fields my-custom-text">
                      <span className='form-field-label'>
                        {t('Destination City/Area')}
                      </span>
                      <div className="">
                        {/* <TextBoxComponent  //[privious]
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('Destination City/Area')}
                          key={"DestinationCityArea"+placeKey}
                          onChange={(e) => setoArea(e.target.value)}
                          value={oArea}
                          htmlAttributes={{ maxlength: '100' }}
                        /> */}
                        <TextBoxComponent
                          type="text"
                          className="form-control form-control-sm"
                          placeholder={t('Destination City/Area')}
                          onChange={(e) => setdArea(e.target.value)}
                          key={"pickupcityarea"+placeKey}
                          value={dArea}
                          ref={dAreaRef}
                          htmlAttributes={{ maxlength: '100' }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} lg={4} className='pd-0'>
                  <div className="capacity-form-fields my-custom-text">
                      <span className="form-field-label required">
                        {t('Equipment Available Date')}
                      </span>
                      <div className="">
                        <DatePickerComponent
                          data-msg-containerid="errorForDate"
                          change={dateChangeHandler}
                          name="date"
                          id="date"
                          className="form-control"
                          showClearButton="yes"
                          cssClass="e-medium"
                          locale="en"
                          placeholder={t("Enter date")}
                          maskPlaceholder={'yes'}
                          format="MM/dd/yyyy"
                          enableMask={true}
                          floatLabelType="Never"
                          min={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                          max={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                        >
                          <Inject services={[MaskedDateTime]} value={date} stritMode={true} />
                        </DatePickerComponent>
                        <div id="errorForDate" />
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} lg={8} className='pd-0'>
                  <div className="capacity-form-fields my-custom-text">
                      <span className='form-field-label'>{t('Comments')}</span>
                      <div className="">
                        <TextBoxComponent
                          type="text"
                          multiline={false}
                          cssClass="e-medium"
                          //className="form-control form-control-sm e-input"
                          placeholder={t("Comments")}
                          onChange={(e) => setComments(e.target.value)}
                          key={"Comments"+placeKey}
                          value={comments}
                          htmlAttributes={{ maxlength: '150' }}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row style={{ marginTop: "1rem", padding:'0px' }}>
                <div style={{ padding:0,display:'block' }}>
                  <div
                    //className='primary'
                    className="btn btn-outline-secondary active"
                    //className='mt-3'
                    type="button"
                    //cssClass="e-large"
                    onClick={(e) => {
                      e.preventDefault();
                      resetHandle();
                    }}
                  >
                    {t('Reset')}
                  </div>
                  <button className="my-custom-btn" style={{ marginLeft: "1rem" }} type="submit" onClick={onSubmit} >{t('Submit')}</button>
                </div>
              </Row>
              </Container>
            </form>
          </div>
          <br />
        </div>
      </div>
      <div className='mt-4 mr-4'>
      <ErrorBoundary compName="Capacity Management: GridComponent">
        <div className='mb-3 my-custom-text sub-header-text'> {t('Past 30 Day Capacity Locations')}</div>
        {equipmentHistory && stateList && (
          <GridComponent
            //width={"80%"}
            style={{ marginBottom: '2rem', marginRight: "1rem" }}
            dataSource={equipmentHistory}
            commandClick={commandClickHandler}
            allowPaging={true}
            allowSorting={true}
            //height={250}
            pageSettings={pageSettings}
            // allowTextWrap={true}
            textWrapSettings={wrapSettings}
            editSettings={{ allowEditing: false, allowDeleting: false }}
            sortSettings={{
              columns: [{ field: 'capacityID', direction: 'Descending' }],
            }}
            ref={dataGrid}
            dataBound={gridLoaded}
          >
            <ColumnsDirective>
              <ColumnDirective field="capacityID" type="number" headerTextAlign='Center' headerText="id" width="100" textAlign="Center" visible={false} />

              {/* <ColumnDirective  field="enteredDateTime" headerText='Date Added' width='100' textAlign="Left"/>  */}
              <ColumnDirective
                field="equipmentName" 
                headerText="Equipment<br>Type"
                type="string"
                // headerText={t("Equipment<br>Type")}
                headerTextAlign='Center'
                width="100" textAlign="Left" disableHtmlEncode={false}
                headerTemplate={()=>(
                  <div>{t("Equipment")}<br />{t("Type")}</div>
                )}
                template={(data) => (
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <div>{data.equipmentName}</div>
                    <MdOutlineFileCopy onClick={()=>commandClickHandler({ rowData: data, commandColumn:{ type : 'Edit'} })} title={t("Copy")} className='action-btn-table' style={{ marginLeft: '1rem' }} />
                    <RiDeleteBin2Line onClick={()=>commandClickHandler({ rowData: data, commandColumn:{ type : 'Delete'} })} title={t("Delete")} className='action-btn-table' style={{ marginLeft: '1rem' }} />
                  </div>)}
              />
              <ColumnDirective 
                field="quantityAvailable" 
                headerText="Qty" 
                // headerText={t("Qty")}
                width="100" textAlign="Center" 
                type="number"
                headerTemplate={()=>(
                  <div>{t("Qty")}</div>
                )}
                />
              {/* <ColumnDirective  //[privious]
              field="destinationState" 
              headerText="From" 
              type="string"
              // headerText={t("From")}
              headerTemplate={()=>(
                <div>{t("From")}</div>
              )}
              width="120" textAlign="Center" />
              <ColumnDirective 
                field="originState" 
                headerText="To" 
                // headerText={t("To")}
                width="120" textAlign="Center" 
                type="string"
              headerTemplate={()=>(
                <div>{t("To")}</div>
              )}
              /> */}
              <ColumnDirective 
              field="originState" 
              headerText="From" 
              type="string"
              // headerText={t("From")}
              headerTemplate={()=>(
                <div>{t("From")}</div>
              )}
              width="120" textAlign="Center" />
              <ColumnDirective 
                field="destinationState" 
                headerText="To" 
                // headerText={t("To")}
                width="120" textAlign="Center" 
                type="string"
              headerTemplate={()=>(
                <div>{t("To")}</div>
              )}
              />
              <ColumnDirective
                field="equipAvailableDate"
                headerText="Available <br> Date"
                // headerText={t("Available <br> Date")}
                headerTemplate={()=>(
                  <div>{t("Available")}<br />{t("Date")}</div>
                )}
                type="dateTime"
                format="MM/dd/yyyy"
                width="120"
                textAlign="Center"
                disableHtmlEncode={false}
              />
              <ColumnDirective 
                field="comments" 
                headerText="Comments" 
                type="string"
                // headerText={t("Comments")}
                width="200" textAlign="Center" 
                headerTemplate={()=>(
                  <div>{t("Comments")}</div>
                )}
              />
              {/* <ColumnDirective headerText="Delete" width="120" textAlign="Center" commands={commandSettings} />
              <ColumnDirective field="Duplicate" width="75" textAlign="Center" commands={commandSettingsCopy} /> */}
              {/* <ColumnDirective field="Duplicate" width="0" textAlign="Center" /> */}
            </ColumnsDirective>
            <Inject services={[Page, CommandColumn, Sort]} />
          </GridComponent>
        )}
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default CapacityBoard;

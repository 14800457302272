import toast from 'react-hot-toast';
import { getTheme } from './ThemeUtils';


export const showToast = (message, type, inTheme , other ) => {
    const theme = (inTheme || getTheme());
    const duration = 5000;
    if(theme === "dark"){
        const style = {
            borderRadius: '10px',
            background: "#282c34",
            color: '#fff',
        };
        if(type === "success"){
            toast.success(message, { style, duration })
        }
        else if(type === "error"){
            toast.error(message, { style, duration })
        }
        else{
            toast(message, { style, duration})
        }
    }
    else{
        if(type === "success"){
            toast.success(message, { duration })
        }
        else if(type === "error"){
            toast.error(message, { duration })
        }
        else{
            toast(message, { duration })
        }
    }
}
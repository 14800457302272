import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';

import { ToastComponent } from '@syncfusion/ej2-react-notifications';
import Footer from './components/Footer';
import Login from './components/Login';
import TripManagement from './components/TripManagement/TripManagement';
import ThemeContext from './components/Theme/ThemeContext';

import CapacityBoard from './components/CapacityBoard/CapacityBoard';
import TokenProvider from './components/TokenProvider/TokenProvider';
import { DEFAULT_THEME, getLocale, getTheme, setCurrentTheme } from './components/Theme/ThemeUtils';

import Home from './components/Home/Home';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import Sidebar from './components/Sidebar/Sidebar';

function App() {
  const { token, setToken } = TokenProvider();
  const toast = useRef(null);
  const [timer, setTimer] = useState();
  const  firstTime = useRef(new Date());

  const lightThemeUrl = './bootstrap5.css';
  const darkThemeUrl = './bootstrap5-dark.css';
  const [theme, setTheme] = useState(getTheme());
	const [localeState, setLocaleState] = useState(getLocale());

  setInterval(() => {
    if (token) {
      if (firstTime.current === '' || firstTime.current === undefined) {
        firstTime.current = new Date();
        return;
      }

      let tempDate = new Date(firstTime);
      tempDate.setMinutes(tempDate.getMinutes() + 119); //19

      if (new Date() > (tempDate)) {
        setToken('');
        tempDate = null;
        return;
      }

      tempDate = new Date(firstTime);
      tempDate.setMinutes(tempDate.getMinutes() + 115); //19

      if (new Date() > tempDate) {
        //console.log(firstTime, tempDate);

        toastCreated();
        return;
      }
    } else {
      firstTime.current = null;
    }
  }, 30000);

  useEffect(() => {
    setCurrentTheme(getTheme());
    setTheme(getTheme());

    if (token === '' || token === undefined || !token) {
      //	return <Login setToken={setToken} setTimer={setTimer} />;
    } else {
      firstTime.current = timer;
    }
  }, [token, setToken]);

  if (!token) {
    return <Login setToken={setToken} setTimer={setTimer} />;
  }

  function toastCreated() {
    toast.current.show();
  }

  function toggleTheme() {
    setCurrentTheme(theme === 'light' ? 'dark' : 'light');
    setTheme(theme === 'light' ? 'dark' : 'light');
    //setToken({...token, theme: theme});
  }

  const themeUrl = theme === 'light' ? lightThemeUrl : darkThemeUrl;

  return (
    <ThemeContext.Provider
      value={{ theme, toggleTheme, localeState, setLocaleState }}
      //style={{ backgroundColor: (theme === "dark" ? "#141a17" : "white"), height: "100%"}}
      // data-bs-theme="dark"
    >
      <link rel="stylesheet" href={themeUrl} />
      <div>
        <ErrorBoundary compName="Carrier Portal Whole App">
          <ToastComponent
            id="toast_target"
            ref={toast}
            title="Expiring Session..."
            content="Your session is about to expire. Please logout."
            position={{ X: 'Center', Y: 'Top' }}
            //created={toastCreated.bind(this)}
          />
          <div className={'App-header ' + (theme === 'dark' ? 'isDarkTheme' : ' ')} style={{ backgroundColor: theme === 'dark' ? '#141a17' : 'white' }}>
            <Sidebar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/CapacityBoard" element={<CapacityBoard />} />
              <Route path="/TripManagement" element={<TripManagement />} />
            </Routes>
          </div>
          <Footer />
        </ErrorBoundary>
      </div>
    </ThemeContext.Provider>
  );
}

export default App;

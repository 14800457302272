import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Button, NavLink } from 'react-bootstrap';
import { Accordion } from 'react-bootstrap';
import agent from '../../api/agent';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Logo from './../../images/CM_Logo.png';
import { BiHelpCircle, BiLogOut, BiBulb, BiSun, BiMoon, BiMenu } from 'react-icons/bi'; // import icons
import { useLocation } from 'react-router-dom';
import ThemeContext from '../Theme/ThemeContext';
import CSLogo from './../../images/cleanSlate.png'
import CMLogo from './../../images/Cedric-Millar-Logo-Horizontal1.png'
import CMLogoCS from './../../images/cleanSlate.png'
import CMWhite from './../../images/Cedric-Millar-Logo-Horizontal-white.svg'
import CSColor from './../../images/CleanSlate Logo - Color.svg'
import { useTranslation } from 'react-i18next';
import { setCulture } from '@syncfusion/ej2-base';
import { getLocale, setCurrentLocale } from '../Theme/ThemeUtils';
import { showToast } from '../Theme/Utils';
import TokenProvider, { logOutUser } from '../TokenProvider/TokenProvider';
import { Animation } from '@syncfusion/ej2-base';
import './Sidebar.css';
// import './OffCanvas.css'; // Import the custom CSS styles

const Sidebar = () => {
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const { theme, toggleTheme, setLocaleState } = useContext(ThemeContext);
    const { token, setToken } = TokenProvider();
    const [langFlag, setLangFlag] = useState(getLocale());

    const [showOffcanvas, setShowOffcanvas] = useState(false);

    const handleToggleOffcanvas = () => setShowOffcanvas(!showOffcanvas);

    const handleClickLogout = () => {
        setToken("");
        logOutUser();
    }

    useEffect(() => {
        if (token?.locale) {
            let newLocale = token.locale || getLocale();
            setLangFlag(newLocale);
            i18n.changeLanguage(newLocale);
            setCulture((newLocale === 'fr' ? 'fr-FR' : 'en-US'))
        }
    }, [i18n, token])

    const handleChangeLanguage = (x) => {
        if (x.checked) {
          setLangFlag('en');
          setCurrentLocale('en');
          i18n.changeLanguage('en');
          setToken({...token, locale: 'en'});
          setLocaleState('en');
          agent.PostThemeCarrierPortal(token.token, token.userid, theme, 'en')
          .catch((error) => {
            console.log("Error in calling API: ",error);
            if(error?.response?.status === 401) {
              showToast(t('Your session has expired. Please login again.'), 'error');
              logOutUser();
            }
          })
        } else {
          setLangFlag('fr');
          setCurrentLocale('fr');
          i18n.changeLanguage('fr');
          setToken({...token, locale: 'fr'});
          setLocaleState('fr');
          agent.PostThemeCarrierPortal(token.token, token.userid, theme, 'fr')
          .catch((error) => {
            console.log("Error in calling API: ",error);
            if(error?.response?.status === 401) {
              showToast(t('Your session has expired. Please login again.'), 'error');
              logOutUser();
            }
          })
        }
      }

    const getActiveKey = (pathname) => {
        switch (pathname) {
            case "/track-trips":
            case "/track-backhaul":
                return "0";
            case "/ratecall-details":
            case "/ratecall-report":
            case "/ratecall-defaults":
                return "1";
            default:
                return null;
        }
    };


    function ThemeToggleButton() {
        const { theme, toggleTheme } = useContext(ThemeContext);

        return (
          <Nav.Item
            //cssClass="e-primary"
            onClick={x => {
              toggleTheme();
              agent.PostThemeCarrierPortal(token.token, token.userid, theme === "dark" ? "light" : "dark", getLocale())
              .catch((error) => {
                console.log("Error in calling API: ",error);
                if(error?.response?.status === 401) {
                  showToast(t('Your session has expired. Please login again.'), 'error');
                  logOutUser();
                }
              })
            }}
            className={`btn-light`}
            style={{ cursor: "pointer" }}
          //style={{ color: theme === 'dark' ? '#fff' : '#444c54' }}
          >
            {theme === 'light' ? (
              <div>
                <BiMoon />{" " + t('Dark')}
              </div>
            ) : (
              <div>
                <BiSun />{" " + t('Light')}
              </div>
            )}
          </Nav.Item>
        );
    }

    return (
        <div style={{ minHeight: "2rem" }}>
            <Navbar variant="pills" bg={theme === 'dark' ? 'dark' : 'light'} >
                <Container fluid>
                    <Navbar.Toggle style={{ display: 'block' }} aria-controls={`offcanvasNavbar-expand-${!showOffcanvas}`} onClick={handleToggleOffcanvas}>
                        <BiMenu style={{ color: theme === "dark" ? "white" : "#212529" }} />
                    </Navbar.Toggle>
                    <Navbar.Brand href="/" className="me-auto">
                        <img
                            src={theme === "light" ? CMLogo : CMWhite}
                            alt="Cedric Millar Integrated Solutions"
                            height={'35px'}
                            style={{ marginRight: "10px" }}
                        />
                    </Navbar.Brand>
                    <div>
                        {process.env.REACT_APP_ENV !== 'prod' && (
                            <h6 className="custom-label m-1" style={{ backgroundColor: 'grey' }}>
                            {process.env.REACT_APP_ENV}
                            </h6>
                        )}
                    </div>
                </Container>
            </Navbar>

            <Offcanvas className={`text-bg-${theme} my-custom-sidebar`} show={showOffcanvas} onHide={handleToggleOffcanvas} placement="start">
                <Offcanvas.Header closeButton closeVariant={theme === "light" ? "dark" : "white"}>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body
                    style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", color: (theme === "light" ? "black" : "white") }}
                >
                    <Nav activekey={location.pathname} style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <Accordion flush style={{ background: 'transparent' }} defaultActiveKey={getActiveKey(location.pathname)}>
                            <Nav.Link style={{ padding: '1rem 1.25rem' }} onClick={handleToggleOffcanvas} href="/" as={Link} to={'/'} active={location.pathname === '/'} className={(location.pathname === '/' ? 'NavLink__active' : '') + " custom-navitem"}>
                                {t('Home')}
                            </Nav.Link>
                            <Nav.Link 
                            style={{ padding: '1rem 1.25rem' }} onClick={handleToggleOffcanvas} href="/" as={Link} to={'/tripmanagement'} active={location.pathname === '/'} className={(location.pathname === '/tripmanagement' ? 'NavLink__active' : '') + " custom-navitem"}
                            >
                                {t('Trip Management')}
                            </Nav.Link>
                            <Nav.Link style={{ padding: '1rem 1.25rem' }} onClick={handleToggleOffcanvas} as={Link} to={'/capacityboard'} className={(location.pathname === '/capacityboard' ? 'NavLink__active' : '') + " custom-navitem"}>
                                {t('Capacity Board')}
                            </Nav.Link>
                            
                        </Accordion>
                    </Nav>
                    <div className='bottom-btns my-custom-text'>
                        <Nav style={{ display: 'flex', flexDirection: 'column' }}>
                            <Nav.Link href="mailto:helpdesk@cedricmillar.com">
                                <BiHelpCircle />{" " + t('Help')}
                            </Nav.Link>
                            <Nav.Link onClick={handleClickLogout}>
                                <BiLogOut /> {t('Logout')}
                            </Nav.Link>
                        </Nav>
                        <Form>
                            <hr />
                            <div style={{ padding: '10px' }}>
                                <ThemeToggleButton />
                            </div>
                            <div id="language-switch-sidebar-container" className='mt-2 mb-2' style={{ padding: '10px' }}>
                                <SwitchComponent
                                    id="language-switch-sidebar"
                                    cssClass="handle-text"
                                    checked={langFlag === "en" ? true : false}
                                    change={handleChangeLanguage}
                                />
                            </div>
                        </Form>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default Sidebar;
